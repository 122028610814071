import { createSlice } from '@reduxjs/toolkit';

import StringsEN from '../static/strings_en'
import StringsES from '../static/strings_es'

export const LocaleStrings = {
	'en': StringsEN,
	'es': StringsES,
}

export const LANGUAGES = {
	EN: 'en',
	ES: 'es',
};

export function getLocaleString(langId, stringId)
{
	//console.log("getLocaleString: "+langId+":"+stringId);

	if (LocaleStrings.hasOwnProperty(langId) && LocaleStrings[langId].hasOwnProperty(stringId))
		return LocaleStrings[langId][stringId];
	return '';
}

export const languageSlice = createSlice({
	name: 'language',
	initialState: {
        value: LANGUAGES.EN
    },
	reducers: {
		setLanguage: (state, action) => {
			state.value = action.payload.value;
		},
		toggleLanguage: (state, action) => {
            if (state.value === LANGUAGES.ES)
                state.value = LANGUAGES.EN;
            else
                state.value = LANGUAGES.ES;
		},
	},
});

export const selectLanguage = (state) => {
	return state.language.value;
}

export const { 
	setLanguage,
	toggleLanguage
} = languageSlice.actions;

export default languageSlice.reducer;
