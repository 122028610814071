import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import store from './store/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
//import GA4React, { useGA4React } from "ga-4-react";

//const ga4react = new GA4React("G-MHJVFJJFGJ", {'debug_mode':false});

(async () => {
	//await ga4react.initialize();
 
	ReactDOM.render(
		<React.StrictMode>
		  <Provider store={store}>
			<BrowserRouter>
			  <App />
			</BrowserRouter>
		  </Provider>
		</React.StrictMode>,
		document.getElementById('root')
	  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
