var DATA = {
  "states": {
    "XX": {
      "id": "XX",
      "name": "Choose State",
      "graphic1": "vssver.scc",
      "graphic2": "stopgreen.gif",
      "showSavings": true,
      "urlInfo": [
      ],
      "billerInfo": {
      }
    },
    "CA": {
      "id": "CA",
      "name": "California",
      "graphic1": "states6.gif",
      "graphic2": "caform.gif",
      "showSavings": true,
      "urlInfo": [
        {
          "name": "City of Mountain View Utilities",
          "url": "https://www.mountainview.gov/depts/admin_services/utility_billing/",
          "phone": ""
        },
        {
          "name": "City of Sunnyvale Utilities Division",
          "url": "https://www.sunnyvale.ca.gov/homes-streets-and-property/utility-billing",
          "phone": "408-730-7400"
        },
        {
          "name": "East Bay Municipal Utility District  (EBMUD)",
          "url": "https://www.ebmud.com/",
          "phone": "1-866-403-2683"
        },
        {
          "name": "San Francisco Water, Power and Sewer",
          "url": "https://sfpuc.org/",
          "phone": "415-351-3399"
        }
      ],
      "billerInfo": {
        "450": {
          "category": "City Util",
          "id": "450",
          "name": "City of Mountain View Utilities",
          "categoryAndId": "City Util:450"
        },
        "468": {
          "category": "City Util",
          "id": "468",
          "name": "San Francisco Water, Power and Sewer",
          "categoryAndId": "City Util:468"
        },
        "477": {
          "category": "City Util",
          "id": "477",
          "name": "City of Sunnyvale Utilities Division",
          "categoryAndId": "City Util:477"
        },
        "520": {
          "category": "City Util",
          "id": "520",
          "name": "East Bay Municipal Utility District  (EBMUD)",
          "categoryAndId": "City Util:520"
        }
      }
    },
    "MI": {
      "id": "MI",
      "name": "Michigan",
      "graphic1": "states25.gif",
      "graphic2": "directpayblue.gif",
      "showSavings": true,
      "urlInfo": [
        {
          "name": "Buckeye Broadband",
          "url": "https://www.buckeyebroadband.com/",
          "phone": "419-724-9800"
        }
      ],
      "billerInfo": {
        "602": {
          "category": "Cable Co",
          "id": "602",
          "name": "Buckeye Broadband",
          "categoryAndId": "Cable Co:602"
        }
      }
    },
    "MN": {
      "id": "MN",
      "name": "Minnesota",
      "graphic1": "states26.gif",
      "graphic2": "directpayblue.gif",
      "showSavings": true,
      "urlInfo": [
        {
          "name": "City of Minneapolis",
          "url": "",
          "phone": ""
        }
      ],
      "billerInfo": {
        "407": {
          "category": "City Util",
          "id": "407",
          "name": "City of Minneapolis",
          "categoryAndId": "City Util:407"
        }
      }
    },
    "OH": {
      "id": "OH",
      "name": "Ohio",
      "graphic1": "states42.gif",
      "graphic2": "directpayblue.gif",
      "showSavings": true,
      "urlInfo": [
        {
          "name": "Buckeye Broadband",
          "url": "https://www.buckeyebroadband.com/",
          "phone": "419-724-9800"
        },
        {
          "name": "City of Akron",
          "url": "https://www.akronohio.gov/cms/Water/Account/index.html",
          "phone": ""
        },
        {
          "name": "City of Cleveland Water",
          "url": "",
          "phone": "216-664-3130"
        },
        {
          "name": "City of Mansfield",
          "url": "",
          "phone": ""
        },
        {
          "name": "City of Springfield",
          "url": "https://springfieldohio.gov/",
          "phone": "937-324-7364"
        },
        {
          "name": "City of Toledo - Water",
          "url": "",
          "phone": ""
        },
        {
          "name": "Erie County Cablevision",
          "url": "https://www.buckeyebroadband.com/",
          "phone": "419-627-0800"
        },
        {
          "name": "Montgomery County Water Services",
          "url": "https://www.mcohio.org/",
          "phone": "phone # on your bill"
        },
        {
          "name": "NE Ohio Reg Sewer Dist (NEORSD)",
          "url": "",
          "phone": ""
        }
      ],
      "billerInfo": {
        "400": {
          "category": "City Util",
          "id": "400",
          "name": "City of Akron",
          "categoryAndId": "City Util:400"
        },
        "407": {
          "category": "City Util",
          "id": "407",
          "name": "City of Cleveland Water",
          "categoryAndId": "City Util:407"
        },
        "408": {
          "category": "City Util",
          "id": "408",
          "name": "NE Ohio Reg Sewer Dist (NEORSD)",
          "categoryAndId": "City Util:408"
        },
        "420": {
          "category": "City Util",
          "id": "420",
          "name": "City of Mansfield",
          "categoryAndId": "City Util:420"
        },
        "434": {
          "category": "Water Co",
          "id": "434",
          "name": "Montgomery County Water Services",
          "categoryAndId": "Water Co:434"
        },
        "440": {
          "category": "City Util",
          "id": "440",
          "name": "City of Springfield",
          "categoryAndId": "City Util:440"
        },
        "450": {
          "category": "Water Co",
          "id": "450",
          "name": "City of Toledo - Water",
          "categoryAndId": "Water Co:450"
        },
        "602": {
          "category": "Cable Co",
          "id": "602",
          "name": "Buckeye Broadband",
          "categoryAndId": "Cable Co:602"
        },
        "612": {
          "category": "Cable Co",
          "id": "612",
          "name": "Erie County Cablevision",
          "categoryAndId": "Cable Co:612"
        }
      }
    },
    "WV": {
      "id": "WV",
      "name": "West Virginia",
      "graphic1": "states59.gif",
      "graphic2": "stophillsdp.gif",
      "showSavings": true,
      "urlInfo": [
        {
          "name": "Mountaineer Gas ",
          "url": "",
          "phone": ""
        }
      ],
      "billerInfo": {
        "185": {
          "category": "Gas and Electric Companies",
          "id": "185",
          "name": "Mountaineer Gas ",
          "categoryAndId": "Gas and Electric Companies:185"
        }
      }
    },
    "WI": {
      "id": "WI",
      "name": "Wisconsin",
      "graphic1": "states60.gif",
      "graphic2": "stopgreen.gif",
      "showSavings": true,
      "urlInfo": [
        {
          "name": "Evansville Water and Light",
          "url": "",
          "phone": ""
        },
        {
          "name": "Kenosha Water Utility",
          "url": "",
          "phone": ""
        },
        {
          "name": "Milwaukee Water Works",
          "url": "",
          "phone": ""
        },
        {
          "name": "Plymouth Utilities",
          "url": "",
          "phone": ""
        }
      ],
      "billerInfo": {
        "401": {
          "category": "Water Co",
          "id": "401",
          "name": "Milwaukee Water Works",
          "categoryAndId": "Water Co:401"
        },
        "403": {
          "category": "City Util",
          "id": "403",
          "name": "Evansville Water and Light",
          "categoryAndId": "City Util:403"
        },
        "405": {
          "category": "City Util",
          "id": "405",
          "name": "Plymouth Utilities",
          "categoryAndId": "City Util:405"
        },
        "500": {
          "category": "City Util",
          "id": "500",
          "name": "Kenosha Water Utility",
          "categoryAndId": "City Util:500"
        }
      }
    }
  }
}

export default DATA
