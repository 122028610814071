import { createImmutableStateInvariantMiddleware } from '@reduxjs/toolkit';
import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import SiteData from '../static/siteData'

import { Row, Col } from 'react-bootstrap'

import { selectLanguage, getLocaleString } from '../store/languageSlice';

function PrintableForm(props) 
{
	let langId = useSelector(selectLanguage);  

	let states = [];

	for(let key in SiteData.states)
	{
		let state = SiteData.states[key];
		if (state.id === "XX")
		{
			continue;
		}
		states.push(state);
	}

	states.sort((a, b) => (a.name > b.name) ? 1 : -1)

	return (
		<div className="page-content">
			<div className="page-content-simple-spacer"></div>
			<h3><p dangerouslySetInnerHTML={{__html: getLocaleString(langId, "PrintableForm.Header")}}></p></h3>
			<p dangerouslySetInnerHTML={{__html: getLocaleString(langId, "PrintableForm.P1")}}></p>
			<div className="center-block">
				{
					states.map((state) => {
						return <Row><strong><a href={ "/pdf/PrintForm"+state.id+".pdf" } download>{ state.name }</a></strong></Row>
					})
				}
			</div>
		</div>
	);
}

export default PrintableForm;