import React, {useCallback} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	setField,
	setBillerInfo,
	setSignupState,
	selectSignupState,
	selectField,
	selectBillerInfo,
	SIGNUPFIELDS,
	BILLERINFOFIELDS
} from '../store/signupSlice';
import PageNavigation from '../page/PageNavigation';
import HomePageImages from '../page/HomePageImages';

import { selectLanguage, getLocaleString } from '../store/languageSlice';
import { useHistory } from 'react-router-dom';
//import { useGA4React } from "ga-4-react";

import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	useParams,
  } from "react-router-dom";
  
import { Form, Button, Col, InputGroup } from 'react-bootstrap'

import SiteData from '../static/siteData'

var parse = require('html-react-parser');

function HomePage({ match, location }) {

	const dispatch = useDispatch();
	const history = useHistory();
	//const ga4 = useGA4React();

	let langId = useSelector(selectLanguage);

	let states = [];

	for(let key in SiteData.states)
	{
		let state = SiteData.states[key];
		if (state.id === "XX")
		{
			continue;
		}
		states.push(state);
	}

	states.sort((a, b) => (a.name > b.name) ? 1 : -1)
  
	let formState = useSelector(selectSignupState);

	let goDisabled = (formState === 'XX');

	return <div>
		<HomePageImages />
		<div className="page-content">
			<div>
				<p dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Home.Page.P1")}}></p>
				<p dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Home.Page.P2")}}></p>
				<br/>
				<div className="center-block">
					<InputGroup style={{width:"auto"}}>
						<Form.Control 
							as="select"
							value={ useSelector(state => selectSignupState(state)) }
							onChange={e => dispatch(setSignupState({ value: e.target.value })) }
							>
								<option value="XX">{ getLocaleString(langId, "Contact.Page.ChooseState") }</option>
							{
							states.map(function(state) {
							return <option key={state.id} value={state.id}>{state.name}</option>
							})
							}
						</Form.Control>
						<Button disabled={ goDisabled } onClick={ () => {
							
							dispatch(setSignupState({ value: formState }));

							/*
							if (ga4)
							{
								ga4.gtag('event', 'set_form_state', { 'form_state': formState });
							}
							*/
							
							history.push('/signup/company');
							
							} }>{ getLocaleString(langId, "Home.Page.Button.Go") }</Button>
					</InputGroup>
				</div>
				<br/>
				<p dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Home.Page.P3")}}></p>
				<p dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Home.Page.P4")}}></p>
				<ul>
					<li dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Home.Page.List1.Item1")}}></li>
					<li dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Home.Page.List1.Item2")}}></li>
					<li dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Home.Page.List1.Item3")}}></li>
					<li dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Home.Page.List1.Item4")}}></li>
					<li dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Home.Page.List1.Item5")}}></li>
					<li dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Home.Page.List1.Item6")}}></li>
				</ul>
				<b><i><p dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Home.Page.P5")}}></p></i></b>
				<b><i><p dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Home.Page.P6")}}></p></i></b>
			</div>
		</div>
	</div>







}

export default HomePage;