import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectLanguage, getLocaleString } from '../store/languageSlice';
import {
	setField,
	setBillerInfo,
	selectField,
	selectBillerInfo,
	SIGNUPFIELDS,
	BILLERINFOFIELDS
} from '../store/signupSlice';
import PageNavigation from '../page/PageNavigation';

import { Form, Row, Col, Card } from 'react-bootstrap'

function PrivacyPolicy({ match, location }) 
{
	let langId = useSelector(selectLanguage);  

	return <div className="page-content">
		<div className="page-content-simple-spacer"></div>
		<h3>{ getLocaleString(langId, "Privacy.Page.Header") }</h3>
		<p dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Privacy.Page.Description")}}></p>
		
		<div className="privacy-body">
			<p className="privacy-header" dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Privacy.Page.Section1.Header")}}></p>
			<p className="privacy-p" dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Privacy.Page.Section1.P1")}}></p>
			<p className="privacy-p" dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Privacy.Page.Section1.P2")}}></p>

			<p className="privacy-header" dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Privacy.Page.Section2.Header")}}></p>
			<p className="privacy-p" dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Privacy.Page.Section2.P1")}}></p>

			<p className="privacy-header" dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Privacy.Page.Section3.Header")}}></p>
			<p className="privacy-p" dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Privacy.Page.Section3.P1")}}></p>

			<p className="privacy-header" dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Privacy.Page.Section4.Header")}}></p>
			<p className="privacy-subheader" dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Privacy.Page.Section4.SubSection1.Header")}}></p>
			<p className="privacy-p" dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Privacy.Page.Section4.SubSection1.P1")}}></p>
			<p className="privacy-subheader" dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Privacy.Page.Section4.SubSection2.Header")}}></p>
			<p className="privacy-p" dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Privacy.Page.Section4.SubSection2.P1")}}></p>
			<p className="privacy-subheader" dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Privacy.Page.Section4.SubSection3.Header")}}></p>
			<p className="privacy-p" dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Privacy.Page.Section4.SubSection3.P1")}}></p>
			<p className="privacy-subheader" dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Privacy.Page.Section4.SubSection4.Header")}}></p>
			<p className="privacy-p" dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Privacy.Page.Section4.SubSection4.P1")}}></p>
			<p className="privacy-subheader" dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Privacy.Page.Section4.SubSection5.Header")}}></p>
			<p className="privacy-p" dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Privacy.Page.Section4.SubSection5.P1")}}></p>
			<p className="privacy-p" dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Privacy.Page.Section4.SubSection5.P2")}}></p>

			<p className="privacy-header" dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Privacy.Page.Section5.Header")}}></p>
			<p className="privacy-p" dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Privacy.Page.Section5.P1")}}></p>

			<p className="privacy-header" dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Privacy.Page.Section6.Header")}}></p>
			<p className="privacy-p" dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Privacy.Page.Section6.P1")}}></p>

			<p className="privacy-header" dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Privacy.Page.Section7.Header")}}></p>
			<p className="privacy-p" dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Privacy.Page.Section7.P1")}}></p>

			<p className="privacy-header" dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Privacy.Page.Section8.Header")}}></p>
			<p className="privacy-p" dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Privacy.Page.Section8.P1")}}></p>

			<p className="privacy-header" dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Privacy.Page.Section9.Header")}}></p>
			<p className="privacy-p" dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Privacy.Page.Section9.P1")}}></p>

			<p className="privacy-header" dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Privacy.Page.Section10.Header")}}></p>
			<p className="privacy-p" dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Privacy.Page.Section10.P1")}}></p>

			<p className="privacy-header" dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Privacy.Page.Section11.Header")}}></p>
			<p className="privacy-p" dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Privacy.Page.Section11.P1")}}></p>
		</div>
	</div>
}

export default PrivacyPolicy;