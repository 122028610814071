import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectLanguage, getLocaleString } from '../store/languageSlice';
import { useHistory } from 'react-router-dom';
import {
	setField,
	setBillerInfo,
	selectField,
	selectBillerInfo,
	selectSignupState,
	selectAllBillerInfo,
	selectFormInfo,
	SIGNUPFIELDS,
	BILLERINFOFIELDS
} from '../store/signupSlice';
import PageNavigation from '../page/PageNavigation';
import AlertNoState from '../page/AlertNoState';
import MultiStepProgress from '../page/MultiStepProgress';
//import { useGA4React } from "ga-4-react";

import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	useParams,
  } from "react-router-dom";

import { getBillerDetails, undefinedIfEmpty, onlyLastFour } from '../utils/signupUtils';

import { Form, Row, Col, Card, Button, Alert, Spinner, Table } from 'react-bootstrap'

import SiteData from '../static/siteData'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle, faEdit } from '@fortawesome/fontawesome-free-solid'

import { submitFormData } from '../utils/submitFormUtils';

function MissingBillerInfo(props)
{
	return <span className="authorize-warning"><FontAwesomeIcon icon={ faExclamationCircle } />&nbsp;{ props.title }</span>
}

function PersonalInfoField(props)
{
	return props.value?<span>{ props.value }</span>:<span className="authorize-warning"><FontAwesomeIcon icon={ faExclamationCircle } />&nbsp;{ props.error }</span>
}

export function SectionBillerInfo(props)
{
	let langId = useSelector(selectLanguage);

	return <div>
		<table className="summary-info-table">
			<tbody>
				{
					props.signupBillerInfos.length > 0 ?
						props.signupBillerInfos.map(function(signupBillerInfo) {

							let billerDetails = getBillerDetails(props.stateBillerInfo, signupBillerInfo[BILLERINFOFIELDS.BILLERID]);
							let billerName = billerDetails?billerDetails.name:"Unknown";
							let billerAcctNumber = signupBillerInfo[BILLERINFOFIELDS.ACCOUNTNUMBER];

							let keyStr = signupBillerInfo[BILLERINFOFIELDS.BILLERID]+"_"+billerAcctNumber;

							return <>
								<tr key={ keyStr }>
									<td>{ getLocaleString(langId, "MainForm.Page.Authorize2.Label.Biller") }</td>
									<td>{ billerName }</td>
								</tr>
								<tr className="summary-info-table-tr-no-border">
									<td>&nbsp;</td>
									<td><PersonalInfoField value={ billerAcctNumber } error={ getLocaleString(langId, "MainForm.Page.Authorize2.Error.BillerAcctNumMissing") } /></td>
								</tr>
							</>
						})
						:
						<MissingBillerInfo title={ getLocaleString(langId, "MainForm.Page.Authorize2.Error.BillerInfoMissing") } />
				}
				</tbody>
			</table>
		</div>
}

export function SectionPersonalInfo(props)
{
	let langId = useSelector(selectLanguage);

	return <div>
		<table className="summary-info-table">
			<tbody>
				<tr>
					<td>{ getLocaleString(langId, "MainForm.Page.Authorize2.Label.Name") }</td>
					<td><PersonalInfoField value={ props.personalInfo.name } error={ getLocaleString(langId, "MainForm.Errors.NameMissing") } /></td>
				</tr>
				<tr>
					<td>{ getLocaleString(langId, "MainForm.Page.Authorize2.Label.Address") }</td>
					<td><PersonalInfoField value={ props.personalInfo.address } error={ getLocaleString(langId, "MainForm.Errors.AddressMissing") } /></td>
				</tr>
				<tr className="summary-info-table-tr-no-border">
					<td>&nbsp;</td>
					<td>
						<PersonalInfoField value={ props.personalInfo.city } error={ getLocaleString(langId, "MainForm.Errors.CityMissing") } />,&nbsp;
						<PersonalInfoField value={ props.personalInfo.state } error={ getLocaleString(langId, "MainForm.Errors.StateMissing") } />&nbsp;
						<PersonalInfoField value={ props.personalInfo.zip } error={ getLocaleString(langId, "MainForm.Errors.ZipMissing") } />
					</td>
				</tr>
				{
					props.personalInfo.phoneNumber && <tr>
						<td>{ getLocaleString(langId, "MainForm.Page.Authorize2.Label.Phone") }</td>
						<td><PersonalInfoField value={ props.personalInfo.phoneNumber } error="" /></td>
					</tr>
				}
				{
					props.personalInfo.emailAddress && <tr>
						<td>{ getLocaleString(langId, "MainForm.Page.Authorize2.Label.Email") }</td>
						<td><PersonalInfoField value={ props.personalInfo.emailAddress } error="" /></td>
					</tr>
				}			
			</tbody>
		</table>
	</div>
}

export function SectionBankingInfo(props)
{
	let langId = useSelector(selectLanguage);

	return <div >
		<table className="summary-info-table">
			<tbody>
				<tr>
					<td>{ getLocaleString(langId, "MainForm.Page.Authorize2.Label.FIName") }</td>
					<td><PersonalInfoField value={ props.bankingInfo.fiName } error={ getLocaleString(langId, "MainForm.Errors.FINameMissing") } /></td>
				</tr>
				<tr>
					<td>{ getLocaleString(langId, "MainForm.Page.Authorize2.Label.FICity") }</td>
					<td><PersonalInfoField value={ props.bankingInfo.fiCity } error={ getLocaleString(langId, "MainForm.Errors.FICityMissing") } /></td>
				</tr>
				<tr>
					<td>{ getLocaleString(langId, "MainForm.Page.Authorize2.Label.AcctNumber") }</td>
					<td><PersonalInfoField value={ props.bankingInfo.fiAccountNumber } error={ getLocaleString(langId, "MainForm.Errors.AcctNumberMissing") } /></td>
				</tr>
				<tr>
					<td>{ getLocaleString(langId, "MainForm.Page.Authorize2.Label.RoutingNumber") }</td>
					<td><PersonalInfoField value={ props.bankingInfo.fiRoutingNumber } error={ getLocaleString(langId, "MainForm.Errors.RoutingNumberMissing") } /></td>
				</tr>
			</tbody>
		</table>
	</div>
}

function Authorize(props) 
{
	let items = [];

	const dispatch = useDispatch();
	const history = useHistory();
	//const ga4 = useGA4React();

	const [formSeqNumber, setFormSeqNumber] = useState(undefined);

	let values = useSelector(selectFormInfo);

	let langId = useSelector(selectLanguage);

	let stateId = useSelector(selectSignupState);

	let stateBillerInfo = SiteData.states[stateId].billerInfo;

	let signupBillerInfos = useSelector(selectAllBillerInfo);

	let goodBillerAccts = 0;
	let goodBillerIds = [];
	signupBillerInfos.map(function(signupBillerInfo) {
		if (signupBillerInfo[BILLERINFOFIELDS.ACCOUNTNUMBER] !== undefined)
		{
			goodBillerAccts++;

			let billerIds = signupBillerInfo[BILLERINFOFIELDS.BILLERID].split(":");
			if (billerIds.length > 0)
			{
				goodBillerIds.push((stateId === 'OH' || stateId === 'PA'?'':stateId) + billerIds[billerIds.length-1]);
			}
		}
	})
	
	let personalInfo = {};
	
	personalInfo.firstName = undefinedIfEmpty(useSelector(state => selectField(state, SIGNUPFIELDS.FIRSTNAME)));
	personalInfo.lastName = undefinedIfEmpty(useSelector(state => selectField(state, SIGNUPFIELDS.LASTNAME)));
	personalInfo.address = undefinedIfEmpty(useSelector(state => selectField(state, SIGNUPFIELDS.ADDRESS)));
	personalInfo.city = undefinedIfEmpty(useSelector(state => selectField(state, SIGNUPFIELDS.CITY)));
	personalInfo.state = undefinedIfEmpty(useSelector(state => selectField(state, SIGNUPFIELDS.STATE)));
	personalInfo.zip = undefinedIfEmpty(useSelector(state => selectField(state, SIGNUPFIELDS.ZIP)));
	personalInfo.phoneNumber = undefinedIfEmpty(useSelector(state => selectField(state, SIGNUPFIELDS.PHONENUMBER)));
	personalInfo.emailAddress = undefinedIfEmpty(useSelector(state => selectField(state, SIGNUPFIELDS.EMAILADDRESS)));
	personalInfo.name = personalInfo.firstName?(personalInfo.lastName?personalInfo.firstName+" "+personalInfo.lastName:personalInfo.firstName):undefined;

	let bankingInfo = {};

	bankingInfo.fiName = undefinedIfEmpty(useSelector(state => selectField(state, SIGNUPFIELDS.FINAME)));
	bankingInfo.fiCity = undefinedIfEmpty(useSelector(state => selectField(state, SIGNUPFIELDS.FICITY)));
	bankingInfo.fiRoutingNumber = onlyLastFour(undefinedIfEmpty(useSelector(state => selectField(state, SIGNUPFIELDS.FIROUTINGNUMBER))));
	bankingInfo.fiAccountNumber = onlyLastFour(undefinedIfEmpty(useSelector(state => selectField(state, SIGNUPFIELDS.FIACCOUNTNUMBER))));
	bankingInfo.fiAccontTypeChecking = useSelector(state => selectField(state, SIGNUPFIELDS.FIACCONTTYPECHECKING));
	
	if (bankingInfo.fiAccountNumber)
	{
		bankingInfo.fiAccountNumber = bankingInfo.fiAccountNumber + " (" + (bankingInfo.fiAccontTypeChecking?getLocaleString(langId, "MainForm.Strings.CheckingAccount"):getLocaleString(langId, "MainForm.Strings.SavingsAccount")) + ")";
	}

	let authorizationCode = useSelector(state => selectField(state, SIGNUPFIELDS.AUTHORIZATIONCODE));

	let showWarning = false;

	if (
		signupBillerInfos.length <= 0 ||
		signupBillerInfos.length !== goodBillerAccts ||
		bankingInfo.fiName === undefined ||
		bankingInfo.fiCity === undefined ||
		bankingInfo.fiAccountNumber === undefined ||
		bankingInfo.fiRoutingNumber === undefined ||
		personalInfo.name === undefined ||
		personalInfo.address === undefined ||
		personalInfo.city === undefined ||
		personalInfo.state === undefined ||
		personalInfo.zip === undefined
		)
		showWarning = true;
	
	let disableAuthorization = showWarning || (authorizationCode === undefined || authorizationCode.length != 4);

	const [formSubmitted, setFormSubmitted] = useState(false);
	const [formSubmitError, setFormSubmitError] = useState('');
	const [showSpinner, setShowSpinner] = useState(false);

	const doSubmit = async function(values)
	{
		let valuesNew = JSON.parse(JSON.stringify(values));

		if (valuesNew && valuesNew.billerInfo)
		{
			let signupState = stateId;

			if (signupState === "OH" || signupState === "PA")
			{
				signupState = "";
			}

			for(let key in valuesNew.billerInfo)
			{
				let signupBillerInfo = valuesNew.billerInfo[key];
				if (signupBillerInfo && signupBillerInfo.hasOwnProperty(BILLERINFOFIELDS.BILLERID))
				{
					let billerDetails = getBillerDetails(stateBillerInfo, signupBillerInfo[BILLERINFOFIELDS.BILLERID]);
					signupBillerInfo.name = billerDetails?billerDetails.name:"Unknown";

					let billerIds = signupBillerInfo[BILLERINFOFIELDS.BILLERID].split(":");
					if (billerIds.length > 1)
					{
						signupBillerInfo[BILLERINFOFIELDS.BILLERID] = signupState + billerIds[1];
					}				
				}
			}
		}

		let sendObj = {
			command: "signup-authorize",
			version: 1,
			source: "direcpaymentplan",
			formData: valuesNew
		}
		
		let responseObj = await submitFormData(sendObj);
		
		if (responseObj && responseObj.response && responseObj.response.statusCode == 200)
		{
			if (responseObj.response.formSeqNumber)
			{
				setFormSeqNumber(responseObj.response.formSeqNumber);
			}

			setFormSubmitted(true);
		}
		else
		{
			setFormSubmitError('There was a problem submitting your information. Try again later.');
		}
		
		setShowSpinner(false);		
	}

    useEffect( () => {
		
		if (formSubmitted)
		{
			dispatch(setField({ field: SIGNUPFIELDS.FORMSEQNUMBER, value: formSeqNumber } ));
			history.push('/signup/print');
		}

    }, [formSubmitted]);

	const labelWidth = 5;

	return <div className="page-content">
		{
			stateId === 'XX' ?
			<AlertNoState />
			:
			<div>
				<MultiStepProgress step="4" />
				<h3 className="form-title">{ getLocaleString(langId, "MainForm.Page.Title.AuthorizeService") }</h3>
				{
					showWarning && 
					<Alert variant="danger"><Row><Col xs="auto" className="align-self-center authorize-warning-header-icon"><span className="authorize-warning"><FontAwesomeIcon icon={ faExclamationCircle } size="2x" /></span></Col><Col><span className="authorize-warning">{ getLocaleString(langId, "MainForm.Errors.MissingInformationWarning") }</span></Col></Row></Alert>
				}
				{ 
					formSubmitError.length > 0 &&
					<Alert variant="danger">{ formSubmitError }</Alert>
				}
				<Card body className="authorize-card text-center">
					<Card.Title>
						{ getLocaleString(langId, "MainForm.Page.Title.CompanyInfo") }
						<div className="authorize-edit-control float-right">
							<Link to="/signup/company?cmd=edit">
								<FontAwesomeIcon icon={ faEdit } />&nbsp;{ getLocaleString(langId, "MainForm.Page.Authorize2.Button.Edit") }
							</Link>
						</div>
					</Card.Title>
					<SectionBillerInfo signupBillerInfos={ signupBillerInfos } stateBillerInfo={ stateBillerInfo } labelWidth={ labelWidth } />
				</Card>
				<div className="authorize-card-spacer"></div>
				<Card body className="authorize-card text-center">
					<Card.Title>
						{ getLocaleString(langId, "MainForm.Page.Title.PersonalInfo") }
						<div className="authorize-edit-control float-right">
							<Link to="/signup/address?cmd=edit">
								<FontAwesomeIcon icon={ faEdit } />&nbsp;{ getLocaleString(langId, "MainForm.Page.Authorize2.Button.Edit") }
							</Link>
						</div>
					</Card.Title>
					<SectionPersonalInfo personalInfo={ personalInfo } labelWidth={ labelWidth }/>
				</Card>
				<div className="authorize-card-spacer"></div>
				<Card body className="authorize-card text-center">
					<Card.Title>
						{ getLocaleString(langId, "MainForm.Page.Title.BankingInformation") }
						<div className="authorize-edit-control float-right">
							<Link to="/signup/bank?cmd=edit">
								<FontAwesomeIcon icon={ faEdit } />&nbsp;{ getLocaleString(langId, "MainForm.Page.Authorize2.Button.Edit") }
							</Link>
						</div>
					</Card.Title>
					<SectionBankingInfo bankingInfo={ bankingInfo } labelWidth={ labelWidth }/>
				</Card>
				<div className="authorize-spacer"></div>
				<p dangerouslySetInnerHTML={{__html: getLocaleString(langId, "MainForm.Page.Authorize2.Trailer.P1")}}></p>
				<p dangerouslySetInnerHTML={{__html: getLocaleString(langId, "MainForm.Page.Authorize2.Trailer.P2")}}></p>
				<p dangerouslySetInnerHTML={{__html: getLocaleString(langId, "MainForm.Page.Authorize2.Trailer.P3")}}></p>
				<Form.Row className="align-items-baseline">
					<Col xs="auto">
						<Form.Label className="auth-code-label">{ getLocaleString(langId, "MainForm.Label.AuthorizationCode2") }</Form.Label>
					</Col>
				</Form.Row>
				<Form.Row className="align-items-baseline">
					<Col xs="auto">
						<Form.Control 
							type="text" 
							value={ authorizationCode || '' }
							onChange={e => dispatch(setField({ field: SIGNUPFIELDS.AUTHORIZATIONCODE, value: e.target.value } )) }
							maxLength="4" style={{width: 80}}
							/>
					</Col>
					<Col xs="auto">
						<Button disabled={ disableAuthorization } onClick={ () => {
							
								setShowSpinner(true);

								/*
								if (ga4)
								{
									ga4.gtag('event', 'authorize', { 
										'form_state': stateId, 
										'biller_count': goodBillerAccts,
										'biller_ids': goodBillerIds
									});

									for(let b=0;b<goodBillerIds.length;b++)
									{
										ga4.gtag('event', 'authorize_biller_'+goodBillerIds[b], { 
											'form_state': stateId, 
											'biller_id': goodBillerIds[b]
										});	
									}
								}
								*/

								setTimeout(function () { doSubmit(values) }, 500);
							}}
							><span>
							{ showSpinner && 
								<span>&nbsp;&nbsp;<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />&nbsp;&nbsp;</span> 							
							}
							{ getLocaleString(langId, "MainForm.Page.Footer.Button.AuthorizeStep") }</span>
							</Button>
					</Col>
				</Form.Row>
			</div>
		}
	</div>
}

export default Authorize;