import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectLanguage, getLocaleString } from '../store/languageSlice';
import {
	setField,
	setBillerInfo,
	selectField,
	selectBillerInfo,
	SIGNUPFIELDS,
	BILLERINFOFIELDS
} from '../store/signupSlice';
import PageNavigation from '../page/PageNavigation';

import { Form, Row, Col, Card, Button, Alert, Spinner } from 'react-bootstrap'

import { submitFormData } from '../utils/submitFormUtils';

function FeedbackForm({ match, location }) 
{
	let langId = useSelector(selectLanguage);  

    const [values, setValues] = useState({
		firstName: '', 
		lastName: '', 
		address: '', 
		address2: '', 
		city: '', 
		state: '', 
		zip: '', 
		phone: '', 
		emailAddress: '', 
		comment: '', 
	})

	const handleInputChange = e => {
        const {name, value} = e.target
        setValues({...values, [name]: value})
	}
	
	const [formSubmitted, setFormSubmitted] = useState(false);
	const [formSubmitError, setFormSubmitError] = useState('');
	const [showSpinner, setShowSpinner] = useState(false);

	// https://dev.to/damcosset/refactor-a-form-with-react-hooks-and-usestate-1lfa

	const doSubmit = async function(values)
	{
		let sendObj = {
			command: "form-feedback",
			version: 1,
			formData: values
		}
	
		let responseObj = await submitFormData(sendObj);
	
		//console.log(JSON.stringify(responseObj, null, 2));
	
		if (responseObj && responseObj.response && responseObj.response.statusCode == 200)
		{
			setFormSubmitted(true);
		}
		else
		{
			setFormSubmitError('There was a problem submitting your information. Try again later.');
		}
		
		setShowSpinner(false);
	}

	let labelCols = 3;

	let enableSubmit = (
		values.firstName.length > 0 && 
		values.lastName.length > 0 && 
		values.address.length > 0 && 
		values.city.length > 0 && 
		values.state.length > 0 && 
		values.zip.length > 0 && 
		values.phone.length > 0 && 
		values.emailAddress.length > 0 &&
		values.comment.length > 0
		);

	return <div className="page-content">
		<div className="page-content-simple-spacer"></div>
		<h3>{ getLocaleString(langId, "Feedback.Page.Header") }</h3>
		{ formSubmitted ? 
			<div>
			<Alert variant="success">Thank you for your information.</Alert>
			</div> 
			:
			<div>
				<p dangerouslySetInnerHTML={{__html: getLocaleString(langId, "Feedback.Page.Description")}}></p>
				{ formSubmitError.length > 0 &&
					<Alert variant="danger">{ formSubmitError }</Alert>
				}
				<Row>
					<Col xs="1">

					</Col>
					<Col xs="10">
						<Card body>
							<Form>
								<Form.Group as={ Row } controlId="firstName">
									<Form.Label column sm={ labelCols }>{ getLocaleString(langId, "Feedback.Page.Label.FirstName") }</Form.Label>
									<Col sm={ 12-labelCols }>
										<Form.Control 
											type="text" 
											name="firstName"
											onChange={ handleInputChange }
											value={ values.firstName }
											/>
									</Col>
								</Form.Group>
								<Form.Group as={ Row } controlId="lastName">
									<Form.Label column sm={ labelCols }>{ getLocaleString(langId, "Feedback.Page.Label.LastName") }</Form.Label>
									<Col sm={ 12-labelCols }>
										<Form.Control 
											type="text" 
											name="lastName"
											onChange={ handleInputChange }
											value={ values.lastName }
											/>
									</Col>
								</Form.Group>
								<Form.Group as={ Row } controlId="address">
									<Form.Label column sm={ labelCols }>{ getLocaleString(langId, "Feedback.Page.Label.Address") }</Form.Label>
									<Col sm={ 12-labelCols }>
										<Form.Control 
											type="text" 
											name="address"
											onChange={ handleInputChange }
											value={ values.address }
											/>
									</Col>
								</Form.Group>
								<Form.Group as={ Row } controlId="address2">
									<Form.Label column sm={ labelCols }>{ getLocaleString(langId, "Feedback.Page.Label.Address2") }</Form.Label>
									<Col sm={ 12-labelCols }>
										<Form.Control 
											type="text" 
											name="address2"
											onChange={ handleInputChange }
											value={ values.address2 }
											/>
									</Col>
								</Form.Group>
								<Form.Group as={ Row } controlId="city">
									<Form.Label column sm={ labelCols }>{ getLocaleString(langId, "Feedback.Page.Label.City") }</Form.Label>
									<Col sm={ 12-labelCols }>
										<Form.Control 
											type="text" 
											name="city"
											onChange={ handleInputChange }
											value={ values.city }
											/>
									</Col>
								</Form.Group>
								<Form.Group as={ Row } controlId="state">
									<Form.Label column sm={ labelCols }>{ getLocaleString(langId, "Feedback.Page.Label.State") }</Form.Label>
									<Col sm={ 12-labelCols }>
										<Form.Control 
											type="text" 
											name="state"
											onChange={ handleInputChange }
											value={ values.state }
											/>
									</Col>
								</Form.Group>
								<Form.Group as={ Row } controlId="zip">
									<Form.Label column sm={ labelCols }>{ getLocaleString(langId, "Feedback.Page.Label.Zip") }</Form.Label>
									<Col sm={ 12-labelCols }>
										<Form.Control 
											type="text" 
											name="zip"
											onChange={ handleInputChange }
											value={ values.zip }
											/>
									</Col>
								</Form.Group>
								<Form.Group as={ Row } controlId="phone">
									<Form.Label column sm={ labelCols }>{ getLocaleString(langId, "Feedback.Page.Label.Phone") }</Form.Label>
									<Col sm={ 12-labelCols }>
										<Form.Control 
											type="text" 
											name="phone"
											onChange={ handleInputChange }
											value={ values.phone }
											/>
									</Col>
								</Form.Group>
								<Form.Group as={ Row } controlId="emailAddress">
									<Form.Label column sm={ labelCols }>{ getLocaleString(langId, "Feedback.Page.Label.Email") }</Form.Label>
									<Col sm={ 12-labelCols }>
										<Form.Control 
											type="text" 
											name="emailAddress"
											onChange={ handleInputChange }
											value={ values.emailAddress }
											/>
									</Col>
								</Form.Group>
								<Form.Group as={ Row } controlId="comment">
									<Form.Label column sm={ labelCols }>{ getLocaleString(langId, "Feedback.Page.Label.Comments") }</Form.Label>
									<Col sm={ 12-labelCols }>
										<Form.Control 
											as="textarea" rows={3}
											name="comment"
											onChange={ handleInputChange }
											value={ values.comment }
											/>
									</Col>
								</Form.Group>

								<div className="text-right">

									<Button disabled={ !enableSubmit } onClick={ async () => {

									setShowSpinner(true);

									setTimeout(function () { doSubmit(values) }, 500);

									} }>
									{ showSpinner && 
										<span>&nbsp;&nbsp;<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />&nbsp;&nbsp;</span> 
									}
									<span>{ getLocaleString(langId, "Feedback.Page.Button.Submit") }</span> 
									</Button>
								</div>
							</Form>
						</Card>
					</Col>
					<Col xs="1">
						
					</Col>
				</Row>
			</div>
		}
	</div>
}

export default FeedbackForm;