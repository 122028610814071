var DATA = {
  "CompanyInfo.Page.Button.Submit": "Submit",
  "CompanyInfo.Page.Description": "<b>Companies:</b> If you would like to offer this service to your customers, please provide the information below and click the Submit button. We will then contact you with information on how you can enroll in the program.",
  "CompanyInfo.Page.Header": "Request Information",
  "CompanyInfo.Page.Label.Address": "Address",
  "CompanyInfo.Page.Label.Address2": "Address2",
  "CompanyInfo.Page.Label.City": "City",
  "CompanyInfo.Page.Label.CompanyName": "Company Name",
  "CompanyInfo.Page.Label.Email": "Email Address",
  "CompanyInfo.Page.Label.FirstName": "First Name",
  "CompanyInfo.Page.Label.LastName": "Last Name",
  "CompanyInfo.Page.Label.Phone": "Phone",
  "CompanyInfo.Page.Label.State": "State",
  "CompanyInfo.Page.Label.Zip": "Zip",
  "Contact.Page.Button.Go": "GO",
  "Contact.Page.ChooseState": "Choose Your State",
  "Contact.Page.Header": "Biller Contact List",
  "FAQ.List.Item1.A": "Complete the four easy steps and your information will be forwarded to each biller who will then notify you of the start-up of the service.",
  "FAQ.List.Item1.Q": "How do I sign-up?",
  "FAQ.List.Item10.A": "Yes.  Enter a new enrollment with your new banking information.  It will replace your current banking information.  Do not close your old account until the payments are working against the new one.  It could take 4 to 6 weeks.",
  "FAQ.List.Item10.Q": "If I currently have direct payment can I use this website to change the biller information to a new bank account?",
  "FAQ.List.Item2.A": "On the date shown on your bill, your biller will inform your financial institution of the amount due. They will automatically pay that amount from your account.<BR>*<I>Policies may vary.</I>",
  "FAQ.List.Item2.Q": "How will my bill be paid?",
  "FAQ.List.Item3.A": "Please continue to pay until notification is made on your bill. Allow up to 4 weeks for processing.",
  "FAQ.List.Item3.Q": "When will this take effect?",
  "FAQ.List.Item4.A": "All financial institutions participate.",
  "FAQ.List.Item4.Q": "What if my account is with a Credit Union or Savings Institution?",
  "FAQ.List.Item5.A": "Each biller will send you a copy of the bill, at least 10 days before it is due and indicate when the amount will be paid. <BR>*<I>Policies may vary.</I>",
  "FAQ.List.Item5.Q": "How will I know how much my bill is?",
  "FAQ.List.Item6.A": "Simply call or write your biller. View our <a target=\"_self\" href=\"biller-contact-list\">Biller Contact List</a> or the number can be found on your bill.",
  "FAQ.List.Item6.Q": "What if I have a question about my bill or want to stop the direct payment plan?",
  "FAQ.List.Item7.A": "Simply call or write your biller. View our <a target=\"_self\" href=\"biller-contact-list\">Biller Contact List</a> or the number or the number can be found on your bill.",
  "FAQ.List.Item7.Q": "What if I want to check the status of my enrollment through the directpaymentplan.com website?",
  "FAQ.List.Item8.A": "Your payment will be clearly itemized on your monthly account statement.",
  "FAQ.List.Item8.Q": "How can I be sure my bill has been paid?",
  "FAQ.List.Item9.A": "No. The billers do not charge for direct payments.",
  "FAQ.List.Item9.Q": "Is there a charge for this service?",
  "FAQ.Page.Description": "The following are commonly asked questions concerning direct payments.",
  "FAQ.Page.Header": "More Information",
  "FAQ.Page.Trailer1": "This program is sponsored by the billers that appear on this site.",
  "FAQ.Page.Trailer2": "If you have feedback on our site, please submit comments using our <a href=\"feedback-form\">Feedback Form</a>.",
  "Feedback.Page.Button.Submit": "Submit",
  "Feedback.Page.Description": "<b>Your comments about our service are important to us!</b> Please fill out the feedback form below and click the submit button. We will take your feedback into consideration as we continue to develop our service.",
  "Feedback.Page.Header": "Feedback Form",
  "Feedback.Page.Label.Address": "Address",
  "Feedback.Page.Label.Address2": "Address2",
  "Feedback.Page.Label.City": "City",
  "Feedback.Page.Label.Comments": "Comments",
  "Feedback.Page.Label.Email": "Email Address",
  "Feedback.Page.Label.FirstName": "First Name",
  "Feedback.Page.Label.LastName": "Last Name",
  "Feedback.Page.Label.Phone": "Phone",
  "Feedback.Page.Label.State": "State",
  "Feedback.Page.Label.Zip": "Zip",
  "Header.Links.Home": "Home",
  "Header.Links.Language.English": "English",
  "Header.Links.Language.Spanish": "Español",
  "Header.Links.MoreInformation": "More Information",
  "Header.Links.PrivacyPolicy": "Privacy Policy",
  "Home.Page.Button.Go": "GO",
  "Home.Page.ChooseState": "Choose Your State",
  "Home.Page.Copyright": "Copyright &copy;2002-##COPYRIGHTYEAR## Stop Processing Center. All rights reserved.",
  "Home.Page.List1.Item1": "The information you enter is immediately forwarded to your biller and not retained on the site.",
  "Home.Page.List1.Item2": "You will continue to receive your bill through the mail or by what ever means you currently use.",
  "Home.Page.List1.Item3": "Your information is protected by a secure Internet site.",
  "Home.Page.List1.Item4": "View our <a href=\"privacy-policy\">Privacy Policy</a> regarding any personal information you may supply through the site.",
  "Home.Page.List1.Item5": "To cancel the service, you must contact your billers directly. This website or e-mail through the site will not cancel the Direct Payment service with your biller.",
  "Home.Page.List1.Item6": "You can change banking information by entering a new signup.",
  "Home.Page.P1": "You are enrolling in Direct Payments with your company (biller). The biller will automatically withdraw the amount due from your bank account. You will be notified on your bill when the service takes effect and at that time all future bills will be paid automatically, not through this website.",
  "Home.Page.P2": "Choose your state, click the <b>Go</b> button to complete the three easy steps and you are on your way to the convenience of Direct Payments.",
  "Home.Page.P3": "To change your banking information</b> please submit a new sign-up. Allow 4-6 weeks.",
  "Home.Page.P4": "<b>To cancel the service</b> you must contact the biller directly. Call the customer service phone number on your bill.",
  "Home.Page.P5": "Note: If you prefer to submit your enrollment by mail, visit the <a href=\"printableform\">Printable Form</a> page to download a mailable enrollment form.",
  "Home.Page.P6": "Companies: Submit information at our <a href=\"request-info\">Company Information Form</a>, to get more information on how to offer this service to your customers.",
  "Home.Page.People.Quote1": "\"A reliable and secure way to pay my bills.\"",
  "Home.Page.People.Quote2": "\"Saves my time and the cost of postage.\"",
  "Home.Page.People.Quote3": "\"We can travel and our bills are paid automatically.\"",
  "Home.Page.People.Quote4": "\"This is great! No more checks to write.\"",
  "Home.Page.People.Quote5": "\"No more worries about late payments.\"",
  "MainForm.Button.Print": "Print",
  "MainForm.Errors.AcctNumberMissing": "Account Number Missing",
  "MainForm.Errors.AddressMissing": "Street Address Missing",
  "MainForm.Errors.AuthorizationLettersNumbers": "Authorization code can only contain letters and numbers. Please correct authorization code and click the 'Authorize Service' button again.",
  "MainForm.Errors.AuthorizationNot4Chars": "Authorization code must be exactly 4 characters long. Please correct authorization code and click the 'Authorize Service' button again.",
  "MainForm.Errors.CityMissing": "City Missing",
  "MainForm.Errors.EmailMissing": "Email Not required",
  "MainForm.Errors.FICityMissing": "Financial Institution City Missing",
  "MainForm.Errors.FINameMissing": "Financial Institution Name Missing",
  "MainForm.Errors.NameMissing": "Name Missing",
  "MainForm.Errors.PhoneMissing": "Phone Number Not Required",
  "MainForm.Errors.PINMissing": "PIN Number Required",
  "MainForm.Errors.RoutingNumberMissing": "Routing Number Missing",
  "MainForm.Errors.StateMissing": "State Missing",
  "MainForm.Errors.ZipMissing": "Zip Missing",
  "MainForm.Page.Authorize.Description": "In order to complete your sign-up, you will need to use an authorization code.  The code serves as your signature authorizing your Internet direct payment enrollment.",
  "MainForm.Page.Authorize.Instructions1": "Please make up and enter a 4 character (alpha/numeric) code.",
  "MainForm.Page.Authorize.Instructions2": "Re-enter code to confirm.",
  "MainForm.Page.Authorize.Label.NextSteps": "Once you have entered your authorization code, click the <b>Next Step</b> button to enter company information.",
  "MainForm.Page.Authorize2.Button.Edit": "Edit",
  "MainForm.Page.Authorize2.Error.BillerAcctNumMissing": "Account Number Missing",
  "MainForm.Page.Authorize2.Error.BillerInfoMissing": "Billing Information is Missing!",
  "MainForm.Page.Authorize2.Label.AcctNumber": "Account Number",
  "MainForm.Page.Authorize2.Label.Address": "Address",
  "MainForm.Page.Authorize2.Label.Biller": "Biller",
  "MainForm.Page.Authorize2.Label.Email": "Email Address",
  "MainForm.Page.Authorize2.Label.FICity": "Financial Institution City",
  "MainForm.Page.Authorize2.Label.FIName": "Financial Institution Name",
  "MainForm.Page.Authorize2.Label.Name": "Name",
  "MainForm.Page.Authorize2.Label.Phone": "Phone",
  "MainForm.Page.Authorize2.Label.RoutingNumber": "Routing Number",
  "MainForm.Page.Authorize2.Trailer.P1": "The information you entered in this website will be provided to your company(ies) for the sole purpose of Direct Payment sign-up.  Delivery of this information is protected by a secured Internet site.",
  "MainForm.Page.Authorize2.Trailer.P2": "I authorize the company(ies) indicated to deduct my payment(s) from the checking/share draft or savings account listed.  I understand that I control my payments, and if at anytime I decide to discontinue this payment service, I will notify each company.",
  "MainForm.Page.Authorize2.Trailer.P3": "In order to complete your sign-up, you will need to provide an <b>Authorization Code</b>. An <b>Authorization Code</b> is <u>any</u> 4 digit alpha numeric sequence. The code serves as your signature authorizing your Internet direct payment enrollment.",
  "MainForm.Page.Authorize2.Trailer.P4": "You will receive an email confirmation (if address was provided).  Print this screen for your paper records. Allow 4 to 6 weeks for processing.",
  "MainForm.Page.Authorize2.Trailer.P5": "Check this box if you are unable to print the screen and want a paper copy mailed to you.",
  "MainForm.Page.BankingInfo.Description.P1": "The Banking Information will be provided to your company(ies) for the sole purpose of Direct Payment sign-up. The delivery of this information is protected by a secure Internet site.",
  "MainForm.Page.BankingInfo.Description.P2": "Enter below the information that appears on the bottom of your check (enter numbers only and not special characters).",
  "MainForm.Page.BankingInfo.Label.AccountNumber": "Account Number",
  "MainForm.Page.BankingInfo.Label.AccountType": "Account Type",
  "MainForm.Page.BankingInfo.Label.Checking": "Checking",
  "MainForm.Page.BankingInfo.Label.CheckingOrShareDraft": "Checking or Share Draft",
  "MainForm.Page.BankingInfo.Label.FICityState": "Financial Institution City, State",
  "MainForm.Page.BankingInfo.Label.FIName": "Financial Institution Name",
  "MainForm.Page.BankingInfo.Label.HowToGetRouting": "Obtain the <b>Routing Number</b> from your bank and the <b>Account Number</b> from your Savings statement. You may incur bank service charges for excessive automated withdrawals.",
  "MainForm.Page.BankingInfo.Label.NextSteps": "Click the <b>Next Step</b> button to review and submit your sign-up.",
  "MainForm.Page.BankingInfo.Label.RoutingNumber": "Routing Number",
  "MainForm.Page.BankingInfo.Label.Savings": "Savings",
  "MainForm.Page.BankingInfo.Label.UseCheck": "Use a check not a deposit slip for this information",
  "MainForm.Page.BillerInfo.Description": "Enter your <b>Billing Account Information</b> below.  Select all of your companies from the drop down menus provided and enter your respective account number(s).",
  "MainForm.Page.BillerInfo.Error.AccountNumberMissing": "A biller <b>Account Number</b> must be provided for each company selected below.  It appears on the billing statement from your company",
  "MainForm.Page.BillerInfo.Label.AccountNumber": "Account Number",
  "MainForm.Page.BillerInfo.Label.Company": "Company",
  "MainForm.Page.BillerInfo.Label.NextSteps": "Once you have entered all of your company and account information click the <b>Next Step</b> button to enter personal information.",
  "MainForm.Page.BillerInfo.Label.SelectCompany": "Select a company",
  "MainForm.Page.Billers.Description": "<b>Direct Payments</b> are offered by many companies so that customers can\nhave their recurring bills paid automatically.  The following companies offer\nthe service in the state you have chosen.  To sign up, press the button below\nand complete the four easy steps and you are on your way to the convenience of\ndirect payments.",
  "MainForm.Page.Billers.Label.NextSteps": "Click the <b>Next Step</b> button to begin the sign-up process.",
  "MainForm.Page.Cookies.Description": "Browser cookies must be enabled for this site to work",
  "MainForm.Page.Cookies.HowTo": "How to Enable Browser Cookies",
  "MainForm.Page.Cookies.Enable1": "<b><nobr>Internet Explorer 5.5</nobr> or older</b></td><td valign=top>Go to the <b>Security</b> tab in the <b>Tools:Internet Options</b> dialog box and click on the <b>Custom Level...</b> button. Enable the <b>Allow per-session cookies (not stored)</b> option.",
  "MainForm.Page.Cookies.Enable2": "<b><nobr>Internet Explorer 6.0</nobr> or newer</b></td><td valign=top>Go to the <b>Privacy</b> tab in the <b>Tools:Internet Options</b> dialog box and set the slider to Medium.",
  "MainForm.Page.Footer.Button.AuthorizeStep": "Authorize Service",
  "MainForm.Page.Footer.Button.BankingChange": "Change Banking Information",
  "MainForm.Page.Footer.Button.ChooseState": "« Back to Choose State",
  "MainForm.Page.Footer.Button.CloseWindow": "Close Window",
  "MainForm.Page.Footer.Button.NextStep": "Next Step »",
  "MainForm.Page.Footer.Button.PersonalChange": "Change Name and Address Information",
  "MainForm.Page.Footer.Button.PrevStep": "« Previous Step",
  "MainForm.Page.Footer.Button.ReturnToHome": "Return to Home",
  "MainForm.Page.Footer.Button.SignupsChange": "Change Company Information",
  "MainForm.Page.Footer.Button.SummaryStep": "Back To Authorize Service",
  "MainForm.Page.PersonalInfo.Description": "Please enter your information below.",
  "MainForm.Page.PersonalInfo.Label.Address": "Address (Billing or Service)",
  "MainForm.Page.PersonalInfo.Label.City": "City",
  "MainForm.Page.PersonalInfo.Label.Email": "Email Address",
  "MainForm.Page.PersonalInfo.Label.EmailConfirmOnly": "The email address will be used for confirmation only, if provided.",
  "MainForm.Page.PersonalInfo.Label.FirstName": "First or Business Name",
  "MainForm.Page.PersonalInfo.Label.LastName": "Last Name",
  "MainForm.Page.PersonalInfo.Label.NextSteps": "Click the <b>Next Step</b> button to enter your banking information.",
  "MainForm.Page.PersonalInfo.Label.Optional": "optional",
  "MainForm.Page.PersonalInfo.Label.Phone": "Phone Number",
  "MainForm.Page.PersonalInfo.Label.State": "State",
  "MainForm.Page.PersonalInfo.Label.Zip": "Zip",
  "MainForm.Page.Title.Authorization": "Authorization",
  "MainForm.Page.Title.AuthorizeService": "Authorize Service - to submit click authorize service",
  "MainForm.Page.Title.BankingInformation": "Banking Information",
  "MainForm.Page.Title.Companies": "Participating Companies",
  "MainForm.Page.Title.CompanyInfo": "Company Information",
  "MainForm.Page.Title.CookiesRequired": "Cookies required",
  "MainForm.Page.Title.PersonalInfo": "Name and Address Information",
  "MainForm.Page.Title.PrintInfo": "Print Confirmation",
  "MainForm.Page.Title.ThankYou": "Thank you for using DirectPaymentPlan.com",
  "MainForm.Page.WrapUp.Label.CheckBill": "Check your bill for phone number",
  "MainForm.Page.WrapUp.P1": "Allow up to 4 weeks for the service to begin.  <b>Please continue to pay your bills until each company notifies you on their statement, that Direct Payments has been activated.</b>",
  "MainForm.Page.WrapUp.P2": "If you are changing banking information allow 4 to 6 weeks for the change to go into effect.  <b>Your payment will be deducted from your old account until the new account information can be used.</b>",
  "MainForm.Page.WrapUp.P3": "To cancel your direct payment service, contact the company&#146;s service department using the phone number on your bill.",
  "MainForm.Page.WrapUp.P4": "For additional information about your company(ies) feel free to call the phone number below or visit their Internet site.",
  "MainForm.Page.WrapUp.ThankYou": "Thanks for using DirectPaymentPlan.com",
  "MainForm.Strings.CheckingAccount": "Checking or Share Draft",
  "MainForm.Strings.SavingsAccount": "Savings Account",
  "Privacy.Page.Description": "Welcome to the <I>DirectPaymentPlan.com</I> website.  Below you will find our privacy policy regarding any personal information you may supply through this site.  Our goal is to protect your information in the process of enrolling you in the direct payment service.",
  "Privacy.Page.Header": "Privacy Policy",
  "Privacy.Page.Section1.Header": "Information required for enrolling in direct payments",
  "Privacy.Page.Section1.P1": "This website is operated for the purpose of enrolling customers (individuals and companies) for direct payment services.  Direct payments (DP) is a service provided by billers (utility and utility like companies) to debit a financial institution account to pay a recurring bill.   Enrolling in DP requires customers to provide personal and banking information to their biller in order to establish the service.  The <I>DirectPaymentPlan.com</I> website is operated on behalf of the participating billers listed on the site who are referred to in the privacy statement as participants.",
  "Privacy.Page.Section1.P2": "The enrollment process is for the initial setup of your service.  All future contact concerning the service should be made directly with your biller.  This site does not provide for future accessing of your information once the enrollment has been completed.  The information is delivered to the biller who maintains it for processing your recurring electronic payment debiting your account.",
  "Privacy.Page.Section10.Header": "Entering the site to enroll or browse",
  "Privacy.Page.Section10.P1": "We gather and analyze data regarding usage of our website, including domain name, number of hits, pages visited, length of user session, etc., to evaluate the usefulness of our site.",
  "Privacy.Page.Section11.Header": "Count on our commitment to your privacy",
  "Privacy.Page.Section11.P1": "You can count on us to keep you informed about how we protect your privacy and limit the sharing of information you provide to us.",
  "Privacy.Page.Section2.Header": "Keeping your information secure",
  "Privacy.Page.Section2.P1": "Keeping your information secure is one of our most important responsibilities.  We value your trust and handle your information with care.   In the process of doing business we collect and process your information for delivery to the billers you have chosen to setup your DP service.  We safeguard information according to established security standards and procedures.  We educate our employees about the importance of confidentiality and customer privacy.",
  "Privacy.Page.Section3.Header": "Keeping your information accurate",
  "Privacy.Page.Section3.P1": "Keeping your account information accurate is very important.  If you ever find that your account information is incorrect or not current please contact your billers directly at the telephone number that appears on your billing statement.",
  "Privacy.Page.Section4.Header": "How your information is used:",
  "Privacy.Page.Section4.SubSection1.Header": "Name, address, and telephone number",
  "Privacy.Page.Section4.SubSection1.P1": "Your billers require your name, address and telephone number to identify you in the process of setting up the DP service.  Billers may also use this to contact you to obtain missing information in order to complete the enrollment.",
  "Privacy.Page.Section4.SubSection2.Header": "Banking information",
  "Privacy.Page.Section4.SubSection2.P1": "Your banking information which includes your financial institution’s routing and transit number, account number and type of account is used to set up the direct payment information in the biller’s billing service or through their third party service company.  The information is used each billing period to originate electronic debits to your account to collect your payment.  This information is maintained in your billing records by the billers you have selected for the DP services.  Your biller may be required to share this information in connection with fraud investigation, an acquisition, or sale of your biller account to another service provider.",
  "Privacy.Page.Section4.SubSection3.Header": "Authorization code",
  "Privacy.Page.Section4.SubSection3.P1": "When you begin the enrollment process you will be asked to enter an authorization code.  The code will be retained with your sign up information and will not be shared with any other party.",
  "Privacy.Page.Section4.SubSection4.Header": "Enrollment E-mail address",
  "Privacy.Page.Section4.SubSection4.P1": "Your E-mail address will be used to confirm your sign up, if provided during enrollment. Regular E-mail is non-encrypted thus the information returned in your confirmation will not contain the actual biller and bank account information.  Your biller may retain this information for communications with you, and for other purposes.",
  "Privacy.Page.Section4.SubSection5.Header": "E-mail requests",
  "Privacy.Page.Section4.SubSection5.P1": "E-mail received to this site serves to provide as means for you to ask questions or to request services from billers who may not be participants in this program.  Your E-mail content and address provided with questions will be used for follow-up and to deliver a response.  Your E-mail requesting action will be forwarded to the appropriate biller.",
  "Privacy.Page.Section4.SubSection5.P2": "Note:  All of the above information is provided to your biller (except for your Authorization Code) for the purpose of direct payments enrollment.  Contact your biller using the phone number on your billing statement for questions concerning their privacy policy and the use and securing of your information.",
  "Privacy.Page.Section5.Header": "Linking to DirectPaymentPlan.com",
  "Privacy.Page.Section5.P1": "If your method of entering the <I>DirectPaymentPlan.com</I> is as a result of a link provided by another website then this site cannot provide for the privacy of information that was used to reach this site.  Once in this site the privacy and security comments stated apply.",
  "Privacy.Page.Section6.Header": "Linking from directpaymentplan.com",
  "Privacy.Page.Section6.P1": "This site contains links to the websites of participating billers.  Our privacy policy will not apply for other sites you my link to.",
  "Privacy.Page.Section7.Header": "Sharing your information",
  "Privacy.Page.Section7.P1": "Except as described above this site will not share this information with any other party.",
  "Privacy.Page.Section8.Header": "About cookies",
  "Privacy.Page.Section8.P1": "Cookies is the ability to place records on your disk drive for interacting with internet users, and can only be read by the server that gave it to you.  This site uses cookies to complete your enrollment.",
  "Privacy.Page.Section9.Header": "Protecting our children",
  "Privacy.Page.Section9.P1": "We do not knowingly solicit data from children, and we do not knowingly market to children.  We recognize that protecting children’s identities and privacy online is important in that the responsibility to do so rests with both the online industry and with parents.",
  "MainForm.Errors.AuthorizationCodeDoesNotMatch": "Authorization codes do not match. Please re-enter.",
  "MainForm.Errors.AuthorizationCodeExactly4Chars": "Authorization code must be exactly 4 characters long.",
  "MainForm.Errors.MissingInformationWarning": "Valid information necessary to complete the form is missing. The missing items are displayed in \"RED\". Please correct these items before authorizing the sign-up.",
  "MainForm.Label.AuthorizationCode": "Authorization Code:",
  "MainForm.Label.AuthorizationCode2": "Authorization Code",
  "MainForm.Label.AuthorizationDate": "Date:",
  "MainForm.Label.AuthorizationRecordNumber": "Record Number:",
  "MainForm.Page.Authorize2.Label.BankingInfo": "Banking Information",
  "MainForm.Page.Authorize2.Label.CompanyInfo": "Company Information",
  "MainForm.Page.Authorize2.Label.PersonalInfo": "Name and Address Information",
  "Header.Tagline": "Use Direct Payment To Pay Your Bills Automatically",
  "Contact.CheckBill": "Check your bill for phone number",
  "MultiStepProgress.Step1": "Company",
  "MultiStepProgress.Step2": "Address",
  "MultiStepProgress.Step3": "Banking",
  "MultiStepProgress.Step4": "Authorize",
  "PrintableForm.Header": "Printable Form",
  "PrintableForm.P1": "Click on your state below to download a printable enrollment form. Follow the instructions on the form to enroll in direct payments.",
}

export default DATA
