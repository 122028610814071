const crypto = require("crypto");

const SEC_SECRET = "a09937bb554d1b6fdd650f1a1c6d6fdf";

export async function submitFormData(sendObj)
{
	let responseObj = {
		status: 500,
		statusText: undefined,
		errorText: undefined,
		response: undefined
	};

	const { lambdaURL } = window['runConfig'];

	try 
	{
		var http = new XMLHttpRequest();
		var url = lambdaURL + sendObj.command;

		console.log("url: " + url);

		let challengeDate = 0;

		if (sendObj.command === "form-feedback" || sendObj.command === "form-request-info")
		{
			let newSendObj = {
				command: sendObj.command,
				subCommand: "get-token"
			}

			var http2 = new XMLHttpRequest();
			let responseObj2 = {
				status: 500,
				statusText: undefined,
				errorText: undefined,
				response: undefined
			};

			http2.open('POST', url, false);

			http2.setRequestHeader('Content-type', 'application/json;charset=UTF-8');

			http2.onreadystatechange = function () 
			{
				if (http2.readyState == 4) 
				{
					responseObj2.status = http2.status;
					responseObj2.statusText = http2.responseText;

					if (http2.status >= 200 && http2.status < 300)
					{
						try
						{
							responseObj2.response = JSON.parse(http2.responseText);
							challengeDate = responseObj2.response.date;
						}
						catch (e2)
						{
							responseObj2.errorText = e2.message;
						}
					}
				}

			}

			await http2.send(JSON.stringify(newSendObj));


			console.log("responseObj2: " + JSON.stringify(responseObj2.response, null, 2));
		}

		let tokenTest = crypto.createHmac('sha256', SEC_SECRET).update(challengeDate + "").digest("base64");

		sendObj.date = challengeDate;
		sendObj.token = tokenTest;

		http.open('POST', url, false);

		http.setRequestHeader('Content-type', 'application/json;charset=UTF-8');

		http.onreadystatechange = function () 
		{
			if (http.readyState == 4) 
			{
				responseObj.status = http.status;
				responseObj.statusText = http.responseText;

				if (http.status >= 200 && http.status < 300)
				{
					try
					{
						responseObj.response = JSON.parse(http.responseText);
					}
					catch (e2)
					{
						responseObj.errorText = e2.message;
					}
				}
			}
		}

		await http.send(JSON.stringify(sendObj));
	}
	catch (e) 
	{
		responseObj.errorText = e.message;
	}

	return responseObj;
}