export function getBillerDetails(billerInfo, billerId)
{
    let billerDetails = undefined;

    let billerIds = billerId.split(":");
    if (billerIds.length > 1)
        billerId = billerIds[1];

    if (billerInfo.hasOwnProperty(billerId))
    {
        billerDetails = billerInfo[billerId];
    }

    return billerDetails;
}

export function undefinedIfEmpty(value)
{
    if (value)
    {
        if (value.trim() === '')
            value = undefined;
    }
    return value;
}

export function onlyLastFour(value)
{
    if (value)
    {
        let newValue = '';

        let numCount = 0;
        for(let i=value.length-1;i>=0;i--)
        {
            let myChar = value.charAt(i);
            if (myChar.match("^[A-​Za-z0-9]$"))
            {
                if (numCount >= 4)
                    myChar = "x";

                numCount++;
             }
             newValue = myChar + newValue;
        }

        value = newValue;
    }

    return value;
}