import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectLanguage, getLocaleString } from '../store/languageSlice';
import {
	setField,
	setBillerInfo,
	selectSignupState,
	selectField,
	selectBillerInfo,
	SIGNUPFIELDS,
	BILLERINFOFIELDS
} from '../store/signupSlice';
import PageNavigation from '../page/PageNavigation';
import AlertNoState from '../page/AlertNoState';
import MultiStepProgress from '../page/MultiStepProgress';

import { Form, Row, Col, Card, InputGroup } from 'react-bootstrap'

function RenderPersonalField(props) 
{
	const dispatch = useDispatch();

	let langId = useSelector(selectLanguage);  

	let fieldValue = useSelector(state => selectField(state, props.fieldId)) || '';

	let style = {};

	if (props.maxLength <= 2)
		style.width = 80;
	else if (props.maxLength <= 10)
		style.width = 160;

	return <Form.Group as={ Row } controlId={ props.fieldId }>
		<Form.Label column sm="4">{ props.fieldLabel }</Form.Label>
		<Col sm="8">
			{
				props.optional === "true" ?
				<InputGroup>
					<Form.Control 
						value={ fieldValue }
						onChange={e => dispatch(setField({ field: props.fieldId, value: e.target.value })) }
						type="text" 
						maxLength={ props.maxLength }
						style={ style }
						placeholder={ '' /* props.prompt */ } />
					<InputGroup.Append>
						<InputGroup.Text id="basic-addon2">{ getLocaleString(langId, "MainForm.Page.PersonalInfo.Label.Optional") }</InputGroup.Text>
					</InputGroup.Append>
				</InputGroup>
				:
				<Form.Control 
					value={ fieldValue }
					onChange={e => dispatch(setField({ field: props.fieldId, value: e.target.value })) }
					type="text" 
					maxLength={ props.maxLength }
					style={ style }
					placeholder={ '' /* props.prompt */ } />
			}
			{
				props.extraInfo && <span><i>{ props.extraInfo }</i></span>
			}
		</Col>
	</Form.Group>;
};

function PersonalInfo({ match, location }) 
{
	const dispatch = useDispatch();

	let langId = useSelector(selectLanguage);    
	let stateId = useSelector(selectSignupState);

	return <div className="page-content">
		{
			stateId === 'XX' ?
			<AlertNoState />
			:
			<div>
				<div>
					<MultiStepProgress step="2" />
					<h3 className="form-title">{ getLocaleString(langId, "MainForm.Page.Title.PersonalInfo") }</h3>
					<p dangerouslySetInnerHTML={{__html: getLocaleString(langId, "MainForm.Page.PersonalInfo.Description")}}></p>
					<Card body className="personal-info-card">
						<Form>
							<RenderPersonalField fieldId={ SIGNUPFIELDS.FIRSTNAME } optional="false" maxLength="100" fieldLabel={ getLocaleString(langId, "MainForm.Page.PersonalInfo.Label.FirstName") } prompt={ getLocaleString(langId, "MainForm.Page.PersonalInfo.Label.FirstName") } />
							<RenderPersonalField fieldId={ SIGNUPFIELDS.LASTNAME } optional="false" maxLength="100" fieldLabel={ getLocaleString(langId, "MainForm.Page.PersonalInfo.Label.LastName") } prompt={ getLocaleString(langId, "MainForm.Page.PersonalInfo.Label.LastName") } />
							<RenderPersonalField fieldId={ SIGNUPFIELDS.ADDRESS } optional="false" maxLength="100" fieldLabel={ getLocaleString(langId, "MainForm.Page.PersonalInfo.Label.Address") } prompt={ getLocaleString(langId, "MainForm.Page.PersonalInfo.Label.Address") } />
							<RenderPersonalField fieldId={ SIGNUPFIELDS.CITY } optional="false" maxLength="100" fieldLabel={ getLocaleString(langId, "MainForm.Page.PersonalInfo.Label.City") } prompt={ getLocaleString(langId, "MainForm.Page.PersonalInfo.Label.City") } />
							<RenderPersonalField fieldId={ SIGNUPFIELDS.STATE } optional="false" maxLength="2" fieldLabel={ getLocaleString(langId, "MainForm.Page.PersonalInfo.Label.State") } prompt={ getLocaleString(langId, "MainForm.Page.PersonalInfo.Label.State") } />
							<RenderPersonalField fieldId={ SIGNUPFIELDS.ZIP } optional="false" maxLength="10" fieldLabel={ getLocaleString(langId, "MainForm.Page.PersonalInfo.Label.Zip") } prompt={ getLocaleString(langId, "MainForm.Page.PersonalInfo.Label.Zip") } />
							<RenderPersonalField fieldId={ SIGNUPFIELDS.PHONENUMBER } optional="true" maxLength="100" fieldLabel={ getLocaleString(langId, "MainForm.Page.PersonalInfo.Label.Phone") } prompt={ getLocaleString(langId, "MainForm.Page.PersonalInfo.Label.Phone") } />
							<RenderPersonalField fieldId={ SIGNUPFIELDS.EMAILADDRESS } optional="true" maxLength="100" fieldLabel={ getLocaleString(langId, "MainForm.Page.PersonalInfo.Label.Email") } prompt={ getLocaleString(langId, "MainForm.Page.PersonalInfo.Label.Email") } extraInfo={ getLocaleString(langId, "MainForm.Page.PersonalInfo.Label.EmailConfirmOnly") }/>
						</Form>
					</Card>
					<div className="form-after-spacing"></div>
					<p dangerouslySetInnerHTML={{__html: getLocaleString(langId, "MainForm.Page.PersonalInfo.Label.NextSteps")}}></p>
				</div>
				<PageNavigation match={ match } location={ location } back="/signup/company" next="/signup/bank" />
			</div>
		}
	</div>
}

export default PersonalInfo;