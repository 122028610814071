import { createSlice } from '@reduxjs/toolkit';

var dateFormat = require("dateformat");

export const NOBILLER = "None:0"
export const DEFAULT_FORMSOURCE = "888";
export const DEFAULT_OPERATOR = "JEG";

export const SIGNUPFIELDS = {
	FORMSTATE: 'formState',
	FIRSTNAME: 'firstName',
	LASTNAME: 'lastName',
	ADDRESS: 'address',
	CITY: 'city',
	STATE: 'state',
	ZIP: 'zip',
	PHONENUMBER: 'phoneNumber',
	EMAILADDRESS: 'emailAddress',
	FINAME: 'fiName',
	FICITY: 'fiCity',
	FIACCONTTYPECHECKING: 'fiAccontTypeChecking',
	FIROUTINGNUMBER: 'fiRoutingNumber',
	FIACCOUNTNUMBER: 'fiAccountNumber',
	AUTHORIZATIONCODE: 'authorizationCode',
	FORMSEQNUMBER: 'formSeqNumber',
	CUSTOMERDATE: 'customerDate',

	FORMSOURCE: 'formSource',
	COMPLETE: 'complete',
	OPERATOR: 'operator',
	NOTE: 'note',
	MERGE: 'merge',
	ERRORS: 'errors'
};

export const BILLERINFOFIELDS = {
	BILLERID: 'billerId',
	NAME: 'name',
	ACCOUNTNUMBER: 'accountNumber',

	COMPLETE: 'complete',
	NOTE: 'note'
};

const getInitialState = () => {
	return {
		signupState: 'XX',
		formInfo: {
			customerDate: dateFormat(Date.now(), 'm/d/yyyy, h:MM:ss TT'),
			fiAccontTypeChecking: true,
			formSource: DEFAULT_FORMSOURCE,
			operator: DEFAULT_OPERATOR,
			complete: true,
			billerInfo: {}
		}
	};
};

export const signupSlice = createSlice({
	name: 'signup',
	initialState: getInitialState(),
	reducers: {
		setField: (state, action) => {
			state.formInfo[action.payload.field] = action.payload.value;
		},
		setSignupState: (state, action) => {
			state.signupState = action.payload.value;
			state.formInfo = getInitialState().formInfo;
		},
		setBillerInfo: (state, action) => {
			if (!state.formInfo.billerInfo.hasOwnProperty(action.payload.id))
				state.formInfo.billerInfo[action.payload.id] = { complete: true };
			state.formInfo.billerInfo[action.payload.id][action.payload.field] = action.payload.value;
		},
		reset: (state, action) => {
			state.signupState = getInitialState().signupState;
			state.formInfo = getInitialState().formInfo;
		}
	},
});

export const { 
	setField,
	setSignupState,
	setBillerInfo,
	reset
} = signupSlice.actions;

export const selectSignupState = (state) => {
	return state.signup.signupState;
}

export const selectField = (state, field) => {
	return state.signup.formInfo[field];
}

export const selectBillerInfo = (state, id, field) => {
	if (state.signup.formInfo.billerInfo.hasOwnProperty(id))
		return state.signup.formInfo.billerInfo[id][field];
	return undefined;
}

export const selectAllBillerInfo = (state) => {
	let billerInfos = [];

	for(let key in state.signup.formInfo.billerInfo)
	{
		let billerInfo = state.signup.formInfo.billerInfo[key];
		if (billerInfo.hasOwnProperty(BILLERINFOFIELDS.BILLERID) && billerInfo[BILLERINFOFIELDS.BILLERID] !== NOBILLER)
		{
			billerInfos.push(billerInfo);
		}
	}

	return billerInfos;
}

export const selectFormInfo = (state, field) => {
	return state.signup.formInfo;
}

export default signupSlice.reducer;
