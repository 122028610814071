import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectLanguage, getLocaleString } from '../store/languageSlice';
import {
	setField,
	setBillerInfo,
	setSignupState,
	selectSignupState,
	selectField,
	selectBillerInfo,
	SIGNUPFIELDS,
	BILLERINFOFIELDS,
	NOBILLER
} from '../store/signupSlice';
import PageNavigation from '../page/PageNavigation';
import AlertNoState from '../page/AlertNoState';
import MultiStepProgress from '../page/MultiStepProgress';

import { Form, Row, Col, Card, Alert } from 'react-bootstrap'

import SiteData from '../static/siteData'

function RenderBiller(props) 
{
	const dispatch = useDispatch();

	let langId = useSelector(selectLanguage);  

	return <div className="biller-card-wrapper">
			<Card body className="biller-card">
				<Form.Group as={ Row } controlId={ 'biller'+props.billerIndex+'_'+BILLERINFOFIELDS.BILLERID }>
					<Form.Label column sm="3">{ getLocaleString(langId, "MainForm.Page.BillerInfo.Label.Company") }</Form.Label>
					<Col sm="9">
						<Form.Control 
							as="select"
							value={ useSelector(state => selectBillerInfo(state, props.billerIndex, BILLERINFOFIELDS.BILLERID)) || NOBILLER }
							onChange={e => dispatch(setBillerInfo({ id:props.billerIndex, field: BILLERINFOFIELDS.BILLERID, value: e.target.value } )) }
							>
								<option key={ NOBILLER } value={ NOBILLER }>Select a company</option>
							{
								Object.keys(props.billerInfo).map(function(billerId) {
									return <option key={props.billerInfo[billerId].categoryAndId} value={props.billerInfo[billerId].categoryAndId}>{props.billerInfo[billerId].name}</option>
								})
							}
						</Form.Control>
					</Col>
				</Form.Group>
				<Form.Group as={ Row } controlId={ 'biller'+props.billerIndex+'_'+BILLERINFOFIELDS.ACCOUNTNUMBER }>
					<Form.Label column sm="3">{ getLocaleString(langId, "MainForm.Page.BillerInfo.Label.AccountNumber") }</Form.Label>
					<Col sm="9">
						<Form.Control 
							value={ useSelector(state => selectBillerInfo(state, props.billerIndex, BILLERINFOFIELDS.ACCOUNTNUMBER)) || '' }
							onChange={e => dispatch(setBillerInfo({ id:props.billerIndex, field: BILLERINFOFIELDS.ACCOUNTNUMBER, value: e.target.value } )) }
							type="text" 
						/>
					</Col>
				</Form.Group>
			</Card>
		</div>
	;
};

function BillerInfo({ match, location }) 
{
	let items = [];

	let langId = useSelector(selectLanguage);  
	let stateId = useSelector(selectSignupState);

	let billerInfo = SiteData.states[stateId].billerInfo;

	let fieldCount = 7;

	for(let i=0;i<fieldCount;i++)
	{
		items.push(<RenderBiller key={ i } billerInfo={ billerInfo } billerIndex={ i } />);
	}

	return <div className="page-content">
		{
			stateId === 'XX' ?
			<AlertNoState />
			:
			<div>
				<div>
					<MultiStepProgress step="1" />
					<h3  className="form-title">{ getLocaleString(langId, "MainForm.Page.Title.CompanyInfo") }</h3>
					<p dangerouslySetInnerHTML={{__html: getLocaleString(langId, "MainForm.Page.BillerInfo.Description")}}></p>
					<Form>
						{ items }
					</Form>
					<div className="form-after-spacing"></div>
					<p dangerouslySetInnerHTML={{__html: getLocaleString(langId, "MainForm.Page.BillerInfo.Label.NextSteps")}}></p>
				</div>
				<PageNavigation match={ match } location={ location } back="/" backLabel="MainForm.Page.Footer.Button.ChooseState" next="/signup/address" />
			</div>
		}
	</div>
}

export default BillerInfo;