import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	useParams,
  } from "react-router-dom";
  import { Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap'
  
  import {
	toggleLanguage,
	setLanguage,
	LANGUAGES,
	selectLanguage,
	getLocaleString
} from '../store/languageSlice';

import SiteData from '../static/siteData'

var qs = require('qs');

function MultiStepProgress(props) {  

	let langId = useSelector(selectLanguage);

	let step1Active = "";
	let step2Active = "";
	let step3Active = "";
	let step4Active = "";
	
	switch(props.step)
	{
		case "4":
			step4Active = " step-active";
		case "3":
			step3Active = " step-active";
		case "2":
			step2Active = " step-active";
		case "1":
			step1Active = " step-active";
	}

	return <ul className="steps page-header-steps">
		<li className={ "step" + step1Active }>
		<div className="step-content">
			<span className="step-circle">1</span>
			<span className="step-text">{ getLocaleString(langId, "MultiStepProgress.Step1") }</span>
		</div>
		</li>
		<li className={ "step" + step2Active }>
		<div className="step-content">
			<span className="step-circle">2</span>
			<span className="step-text">{ getLocaleString(langId, "MultiStepProgress.Step2") }</span>
		</div>
		</li>
		<li className="step">
		<div className={ "step-content" + step3Active }>
			<span className="step-circle">3</span>
			<span className="step-text">{ getLocaleString(langId, "MultiStepProgress.Step3") }</span>
		</div>
		</li>
		<li className="step">
		<div className={ "step-content" + step4Active }>
			<span className="step-circle">4</span>
			<span className="step-text">{ getLocaleString(langId, "MultiStepProgress.Step4") }</span>
		</div>
		</li>
	</ul>
}

export default MultiStepProgress;