import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	useParams,
  } from "react-router-dom";
  import { Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap'
  
  import {
	toggleLanguage,
	setLanguage,
	LANGUAGES,
	selectLanguage,
	getLocaleString
} from '../store/languageSlice';

import SiteData from '../static/siteData'

var qs = require('qs');

function PageNavigation(props) {  

	let langId = useSelector(selectLanguage);

	let queryParams = props.hasOwnProperty("location")?props.location.search || '':'';
	if (queryParams.startsWith("?"))
		queryParams = queryParams.substr(1);
	let queryObj = qs.parse(queryParams);

	let backLabel = props.hasOwnProperty("backLabel")?props.backLabel:"MainForm.Page.Footer.Button.PrevStep";
	let nextLabel = props.hasOwnProperty("nextLabel")?props.nextLabel:"MainForm.Page.Footer.Button.NextStep";

	return queryObj.hasOwnProperty("cmd") && queryObj.cmd === "edit"?
		<div className="page-navigation">
			<Link to="/signup/authorize"><Button variant="primary">{ getLocaleString(langId, "MainForm.Page.Footer.Button.SummaryStep") }</Button></Link>
		</div>
		:
		<div className="page-navigation">
			{ props.hasOwnProperty("back") &&
				<span><Link to={ props.back }><Button variant="secondary">{ getLocaleString(langId, backLabel) }</Button></Link>&nbsp;&nbsp;</span>
			}
			{ props.hasOwnProperty("next") &&
				<span><Link to={ props.next }><Button variant="primary">{ getLocaleString(langId, nextLabel) }</Button></Link></span>
			}
		</div>
}

export default PageNavigation;