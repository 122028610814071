import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectLanguage, getLocaleString } from '../store/languageSlice';
import {
	setField,
	setBillerInfo,
	setSignupState,
	selectSignupState,
	selectField,
	selectBillerInfo,
	SIGNUPFIELDS,
	BILLERINFOFIELDS
} from '../store/signupSlice';
import PageNavigation from '../page/PageNavigation';

import { Form, Row, Col, Card, InputGroup, Alert } from 'react-bootstrap'

import SiteData from '../static/siteData'

function BillerContactList({ match, location }) 
{
	const [currentState, setCurrentState] = useState('CA')

	let langId = useSelector(selectLanguage);  

	let states = [];

	for(let key in SiteData.states)
	{
		let state = SiteData.states[key];
		if (state.id === "XX")
		{
			continue;
		}
		states.push(state);
	}

	states.sort((a, b) => (a.name > b.name) ? 1 : -1)

	return <div className="page-content">
		<div className="page-content-simple-spacer"></div>
		<h3>{ getLocaleString(langId, "Contact.Page.Header") }</h3>
		
		<div className="center-block">
			<Form.Row className="align-items-baseline">
					<Col xs="auto">
						<Form.Label>{ getLocaleString(langId, "Contact.Page.ChooseState") }</Form.Label>
					</Col>
					<Col xs="auto">
						<Form.Control 
							as="select"
							value={ currentState }
							onChange={ e => setCurrentState(e.target.value) }
							>
							{
							states.map(function(state) {
							return <option key={state.id} value={state.id}>{state.name}</option>
							})
							}
						</Form.Control>
					</Col>
			</Form.Row>

		</div>
		<div style={{height: 20}}></div>
		{ SiteData.states[currentState].urlInfo.length <= 0 ?
			<div className="text-center"><Alert variant="warning">State Does Not Have Any Billers</Alert></div>
			:
			<div>
				<table className="billerlist-table">
					{ SiteData.states[currentState].urlInfo.map(function(urlInfo) {
							return <tr>
								<td>
								{
									urlInfo.url.length > 0 ?
									<a href={ urlInfo.url } target="_blank">{ urlInfo.name }</a>
									:
									<span>{ urlInfo.name }</span>
								}
								</td>
								<td>{ urlInfo.phone.length > 0?urlInfo.phone:<i>{ getLocaleString(langId, "MainForm.Page.WrapUp.Label.CheckBill") }</i> }</td>
							</tr>
						})
					}
				</table>
			</div>
		}
	</div>
}

export default BillerContactList;