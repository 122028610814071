import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectLanguage, getLocaleString } from '../store/languageSlice';
import {
	setField,
	setBillerInfo,
	selectSignupState,
	selectField,
	selectBillerInfo,
	SIGNUPFIELDS,
	BILLERINFOFIELDS
} from '../store/signupSlice';
import PageNavigation from '../page/PageNavigation';
import AlertNoState from '../page/AlertNoState';
import MultiStepProgress from '../page/MultiStepProgress';

import { Form, Row, Col, Card } from 'react-bootstrap'

function RenderPersonalField(props) 
{
	const dispatch = useDispatch();

	let langId = useSelector(selectLanguage);  

	return <Form.Group controlId={ props.fieldId }>
		<Form.Label >{ props.fieldLabel }</Form.Label>
			<Form.Control 
				value={ useSelector(state => selectField(state, props.fieldId)) || '' }
				onChange={e => dispatch(setField({ field: props.fieldId, value: e.target.value })) }
				type="text" 
				placeholder={ '' /* props.prompt */ } />
	</Form.Group>;
};

function BankingInfo({  match, location }) 
{
	const dispatch = useDispatch();

	let langId = useSelector(selectLanguage);    
	let stateId = useSelector(selectSignupState);

	let isChecking = useSelector(state => selectField(state, SIGNUPFIELDS.FIACCONTTYPECHECKING));
	let fiRoutingNumber = useSelector(state => selectField(state, SIGNUPFIELDS.FIROUTINGNUMBER));
	let fiAccountNumber = useSelector(state => selectField(state, SIGNUPFIELDS.FIACCOUNTNUMBER));

	return <div className="page-content">
		{
			stateId === 'XX' ?
			<AlertNoState />
			:
			<div>
				<div>
					<MultiStepProgress step="3" />
					<h3 className="form-title">{ getLocaleString(langId, "MainForm.Page.Title.BankingInformation") }</h3>
					<p dangerouslySetInnerHTML={{__html: getLocaleString(langId, "MainForm.Page.BankingInfo.Description.P1")}}></p>
					<p dangerouslySetInnerHTML={{__html: getLocaleString(langId, "MainForm.Page.BankingInfo.Description.P2")}}></p>
					<Card body className="banking-info-card">
						<Form>
							<Row>
								<Col sm="5">
									<RenderPersonalField fieldId={ SIGNUPFIELDS.FINAME } fieldLabel={ getLocaleString(langId, "MainForm.Page.BankingInfo.Label.FIName") } prompt={ getLocaleString(langId, "MainForm.Page.BankingInfo.Label.FIName") } />
									<RenderPersonalField fieldId={ SIGNUPFIELDS.FICITY } fieldLabel={ getLocaleString(langId, "MainForm.Page.BankingInfo.Label.FICityState") } prompt={ getLocaleString(langId, "MainForm.Page.BankingInfo.Label.FICityState") } />
									<Form.Group controlId={ SIGNUPFIELDS.FIACCONTTYPECHECKING }>
										<Form.Label >{ getLocaleString(langId, "MainForm.Page.BankingInfo.Label.CheckingOrShareDraft") }</Form.Label>
											<Form.Check 
												type="radio"
												id={ SIGNUPFIELDS.FIACCONTTYPECHECKING+'_checking' }
												checked={ isChecking }
												label={ getLocaleString(langId, "MainForm.Page.BankingInfo.Label.Checking") }
												onChange={e => dispatch(setField({ field: SIGNUPFIELDS.FIACCONTTYPECHECKING, value: e.target.checked })) }
												/>
											<Form.Check 
												type="radio"
												id={ SIGNUPFIELDS.FIACCONTTYPECHECKING+'_savings' }
												checked={ ! isChecking }
												onChange={e => dispatch(setField({ field: SIGNUPFIELDS.FIACCONTTYPECHECKING, value: !e.target.checked })) }
												label={ getLocaleString(langId, "MainForm.Page.BankingInfo.Label.Savings") }
												/>
									</Form.Group>
								</Col>
								<Col sm="7">
									<div>
										<span><i>{ getLocaleString(langId, "MainForm.Page.BankingInfo.Label.UseCheck") }</i></span><br/>
										<img alt="Sample Check" width="100%" src={process.env.PUBLIC_URL + '/img/check.svg'} />
									</div>
									<Row>
										<Col>
											<Form.Group controlId={ SIGNUPFIELDS.FIROUTINGNUMBER }>
												<Form.Control 
													value={ fiRoutingNumber || '' }
													onChange={e => dispatch(setField({ field: SIGNUPFIELDS.FIROUTINGNUMBER, value: e.target.value })) }
													type="text" 
													placeholder={ '' /* getLocaleString(langId, "MainForm.Page.BankingInfo.Label.RoutingNumber") */ } />
												<Form.Label >{ getLocaleString(langId, "MainForm.Page.BankingInfo.Label.RoutingNumber") }</Form.Label>
											</Form.Group>
										</Col>
										<Col>
											<Form.Group controlId={ SIGNUPFIELDS.FIACCOUNTNUMBER }>
													<Form.Control 
														value={ fiAccountNumber || '' }
														onChange={e => dispatch(setField({ field: SIGNUPFIELDS.FIACCOUNTNUMBER, value: e.target.value })) }
														type="text" 
														placeholder={ '' /* getLocaleString(langId, "MainForm.Page.BankingInfo.Label.AccountNumber") */ } />
													<Form.Label >{ getLocaleString(langId, "MainForm.Page.BankingInfo.Label.AccountNumber") }</Form.Label>
											</Form.Group>
										</Col>
									</Row>
								</Col>
							</Row>
						</Form>
					</Card>
					<div className="form-after-spacing"></div>
					<p dangerouslySetInnerHTML={{__html: getLocaleString(langId, "MainForm.Page.BankingInfo.Label.NextSteps")}}></p>
				</div>
				<PageNavigation match={ match } location={ location } back="/signup/address" next="/signup/authorize" />
			</div>
		}
	</div>
}

export default BankingInfo;