import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle, faEdit } from '@fortawesome/fontawesome-free-solid'
import { Alert } from 'react-bootstrap'

function AlertNoState({ props }) 
{
    return <div className="alert-no-state">
        <Alert variant="danger"><FontAwesomeIcon icon={ faExclamationCircle } />&nbsp;&nbsp;Please return to <a href="/"><strong>Home</strong></a> and choose a state.</Alert>
    </div>
}

export default AlertNoState;