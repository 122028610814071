import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
	toggleLanguage,
	setLanguage,
	LANGUAGES,
	selectLanguage,
	getLocaleString
} from '../store/languageSlice';

import { Navbar, Nav } from 'react-bootstrap'

import { LinkContainer } from 'react-router-bootstrap'

function Header() {

	const dispatch = useDispatch();

	let langId = useSelector(selectLanguage);
  
	return <div>
			<img
				alt="Direct Payment Plan"
				src={process.env.PUBLIC_URL + '/img/header.jpg'}
				width="100%"
			/>
			<Navbar bg="dark" variant="dark" expand="sm" sticky="top">
			<Nav className="mr-auto">
				<LinkContainer to="/">
					<Nav.Link>{ getLocaleString(langId, "Header.Links.Home") }</Nav.Link>
				</LinkContainer>
				<LinkContainer to="/more-info">
					<Nav.Link>{ getLocaleString(langId, "Header.Links.MoreInformation") }</Nav.Link>
				</LinkContainer>
				<LinkContainer to="/privacy-policy">
					<Nav.Link>{ getLocaleString(langId, "Header.Links.PrivacyPolicy") }</Nav.Link>
				</LinkContainer>
			</Nav>
			<Nav>
				<Nav.Link href="#" onClick={ () => { dispatch(toggleLanguage({ })) } }>{ langId === LANGUAGES.EN ? getLocaleString(langId, "Header.Links.Language.Spanish") : getLocaleString(langId, "Header.Links.Language.English") }</Nav.Link>
			</Nav>
		</Navbar>
	</div>
}

export default Header;