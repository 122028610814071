import React from 'react';
import { useSelector } from 'react-redux';

import { selectLanguage, getLocaleString } from '../store/languageSlice';

import { Row, Col } from 'react-bootstrap'

function Footer()
{

	let langId = useSelector(selectLanguage);

	let copyrightStr = getLocaleString(langId, "Home.Page.Copyright").replace("##COPYRIGHTYEAR##", new Date().getFullYear());

	return <div>
		<hr />
		<div className="text-center"><p dangerouslySetInnerHTML={{ __html: copyrightStr }}></p></div>
		{
			/*
				<Row>
				<Col xs="2">&nbsp;</Col>
				<Col xs="4" className="text-center">
				<a target="_blank" href="https://www.trustlogo.com/ttb_searcher/trustlogo?v_querytype=W&v_shortname=SECOV&v_search=https://www.directpaymentplan.com/&x=6&y=5">
					<div style={{ paddingTop: "7px" }}>
						<img alt="Sectigo SSL Site Seal" src={process.env.PUBLIC_URL + '/sectigo_trust_seal_lg_140x54.png'} style={{ border: "none" }} />
					</div>
				</a>
				</Col>
				<Col xs="4" className="text-center" style={{ paddingTop: "14px" }}>
				<a target="_blank" title="Click for the Business Review of Direct Payment, a Bill Paying Service in Newbury OH" href="https://www.bbb.org/cleveland/business-reviews/bill-paying-service/direct-payment-in-newbury-oh-39000766#sealclick">
					<img alt="Click for the BBB Business Review of this Bill Paying Service in Newbury OH" style={{ border: 0 }} src="https://seal-cleveland.bbb.org/seals/blue-seal-250-52-directpayment-39000766.png" />
				</a>
				</Col>
				<Col xs="2">&nbsp;</Col>
				</Row>
			*/
		}
		<div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", justifyContent: "center", gap: "50px" }}>
			<div>
				<a target="_blank" href="https://www.trustlogo.com/ttb_searcher/trustlogo?v_querytype=W&v_shortname=SECOV&v_search=https://www.directpaymentplan.com/&x=6&y=5">
					<div style={{ paddingTop: "7px" }}>
						<img alt="Sectigo SSL Site Seal" src={process.env.PUBLIC_URL + '/sectigo_trust_seal_lg_140x54.png'} style={{ border: "none" }} />
					</div>
				</a>
			</div>
			<div>
				<a target="_blank" title="Click for the Business Review of Direct Payment, a Bill Paying Service in Newbury OH" href="https://www.bbb.org/cleveland/business-reviews/bill-paying-service/direct-payment-in-newbury-oh-39000766#sealclick">
					<img alt="Click for the BBB Business Review of this Bill Paying Service in Newbury OH" style={{ border: 0 }} src="https://seal-cleveland.bbb.org/seals/blue-seal-250-52-directpayment-39000766.png" />
				</a>
			</div>
		</div>
		<br /><br />
	</div>
}

export default Footer;