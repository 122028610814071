var DATA = {
  "CompanyInfo.Page.Button.Submit": "Enviar",
  "CompanyInfo.Page.Description": "<b>Empresas:</b> Si desea ofrecer este servicio a sus clientes, por favor proporcione la siguiente información y haga clic en el botón enviar. Luego lo contactaremos con información sobre cómo puede inscribirse en el programa.",
  "CompanyInfo.Page.Header": "Solicitar información",
  "CompanyInfo.Page.Label.Address": "Dirección",
  "CompanyInfo.Page.Label.Address2": "Dirección 2",
  "CompanyInfo.Page.Label.City": "Ciudad",
  "CompanyInfo.Page.Label.CompanyName": "Nombre de la empresa",
  "CompanyInfo.Page.Label.Email": "Dirección de correo electrónico",
  "CompanyInfo.Page.Label.FirstName": "Nombre",
  "CompanyInfo.Page.Label.LastName": "Apellido",
  "CompanyInfo.Page.Label.Phone": "Teléfono",
  "CompanyInfo.Page.Label.State": "Estado",
  "CompanyInfo.Page.Label.Zip": "Código Postal",
  "Contact.Page.Button.Go": "GO",
  "Contact.Page.ChooseState": "Elija su estado",
  "Contact.Page.Header": "Lista de contactos del Facturador",
  "FAQ.List.Item1.A": "Complete los cuatro sencillos pasos y su información se enviará a cada Facturador que entonces le notificará sobre la puesta en marcha del servicio.",
  "FAQ.List.Item1.Q": "¿Cómo me inscribo?",
  "FAQ.List.Item10.A": "Sí. Introduzca una nueva inscripción con su nueva información bancaria.  Reemplazará su información bancaria actual.  No cierre su cuenta anterior hasta que los pagos estén trabajando contra uno nuevo.  Puede tomar 4 a 6 semanas.",
  "FAQ.List.Item10.Q": "¿Si tengo actualmente pago directo puedo utilizar este sitio web para cambiar la información de Facturador a una nueva cuenta bancaria?",
  "FAQ.List.Item2.A": "En la fecha indicada en su factura, su Facturador informará a su institución financiera de la cantidad adeudada. Automáticamente se pagará esa cantidad de su cuenta.<BR>*<I>Las normas de cada institución pueden variar.</I>",
  "FAQ.List.Item2.Q": "¿Cómo se pagará mi factura?",
  "FAQ.List.Item3.A": "Por favor sigua pagando hasta que se realice la notificación en su cuenta. Por favor permita 4 a 6 semanas para su procesamiento.",
  "FAQ.List.Item3.Q": "¿Cuándo tomará esto efecto?",
  "FAQ.List.Item4.A": "Participan todas las instituciones financieras.",
  "FAQ.List.Item4.Q": "¿Qué pasa si mi cuenta está con una institución de ahorros o cooperativa de crédito?",
  "FAQ.List.Item5.A": "Cada Facturador le enviará una copia de la factura, por lo menos 10 días antes de su vencimiento e indicará cuando se pagará la factura.<BR>*<I>Las normas de cada institución pueden variar.</I>",
  "FAQ.List.Item5.Q": "¿Cómo sabré cuál es mi factura?",
  "FAQ.List.Item6.A": "Simplemente llame o escriba a su Facturador. Vea nuestra <a target=\"_self\" href=\"biller-contact-list\">Lista de contactos de Facturador</a> o el número que se encuentra en su factura.",
  "FAQ.List.Item6.Q": "¿Qué pasa si tengo una pregunta acerca de mi cuenta o deseo detener el plan de pago directo?",
  "FAQ.List.Item7.A": "Simplemente llame o escriba a su Facturador. Vea nuestra <a target=\"_self\" href=\"biller-contact-list\">Lista de contactos de Facturador</a> o el número que se puede encontrar en su factura.",
  "FAQ.List.Item7.Q": "¿Qué pasa si quiero comprobar el estado de mi inscripción a través de la web de directpaymentplan.com?",
  "FAQ.List.Item8.A": "Su pago será claramente detallado en su estado de cuenta mensual.",
  "FAQ.List.Item8.Q": "¿Cómo puedo estar seguro de que se ha pagado mi factura?",
  "FAQ.List.Item9.A": "No, Las empresas no cobran por pagos directos.",
  "FAQ.List.Item9.Q": "¿Hay un cargo por este servicio?",
  "FAQ.Page.Description": "Las siguientes son preguntas frecuentes relativas a los pagos directos.",
  "FAQ.Page.Header": "Más información",
  "FAQ.Page.Trailer1": "Este programa es patrocinado por las empresas que aparecen en este sitio.",
  "FAQ.Page.Trailer2": "Si tiene comentarios sobre nuestro sitio, por favor envíe comentarios utilizando nuestro <a href=\"feedback-form\">Formulario de comentarios</a>.",
  "Feedback.Page.Button.Submit": "Enviar",
  "Feedback.Page.Description": "<b>Sus comentarios acerca de nuestro servicio son importantes para nosotros!</b> Por favor llene el formulario a continuación y haga clic en el botón enviar. Tomaremos sus comentarios en consideración entretanto seguimos desarrollando nuestro servicio.",
  "Feedback.Page.Header": "Formulario de comentarios",
  "Feedback.Page.Label.Address": "Dirección",
  "Feedback.Page.Label.Address2": "Dirección 2",
  "Feedback.Page.Label.City": "Ciudad",
  "Feedback.Page.Label.Comments": "Comentarios",
  "Feedback.Page.Label.Email": "Dirección de correo electrónico",
  "Feedback.Page.Label.FirstName": "Nombre",
  "Feedback.Page.Label.LastName": "Apellido",
  "Feedback.Page.Label.Phone": "Teléfono",
  "Feedback.Page.Label.State": "Estado",
  "Feedback.Page.Label.Zip": "Código Postal",
  "Header.Links.Home": "Home",
  "Header.Links.Language.English": "English",
  "Header.Links.Language.Spanish": "Español",
  "Header.Links.MoreInformation": "Más información",
  "Header.Links.PrivacyPolicy": "Política de privacidad",
  "Home.Page.Button.Go": "GO",
  "Home.Page.ChooseState": "Elija su estado",
  "Home.Page.Copyright": "Copyright © 2002-##COPYRIGHTYEAR## Stop Processing Center. Todos los derechos reservados.",
  "Home.Page.List1.Item1": "La información introducida es inmediatamente transferida a su Facturador y no se conservan en esta página web.",
  "Home.Page.List1.Item2": "Usted continuará recibiendo su factura a través del correo o por medio de lo que ya utiliza actualmente.",
  "Home.Page.List1.Item3": "Su información está protegida por un sitio seguro en Internet.",
  "Home.Page.List1.Item4": "Ver nuestra <a href=\"privacy-policy\">Política de privacidad</a> para cualquier información personal que sea suministrada a través de esta página web.",
  "Home.Page.List1.Item5": "Para cancelar el servicio debe tocar directamente los emisores de facturas. Este sitio web o por correo electrónico a través de la página web no cancelar el servicio de pago directo con su emisor de la factura.",
  "Home.Page.List1.Item6": "Puede intercambiar información bancaria por críticas que entran en un nuevo registro.",
  "Home.Page.P1": "Se está inscribiendo en pagos directos con su empresa (Facturador). El Facturador retirará automáticamente la cantidad debida de su cuenta bancaria. Se le notificará en su factura cuando el servicio entre en vigencia y en ese momento todas las facturas futuras se pagarán automáticamente, no a través de este sitio Web.",
  "Home.Page.P2": "Elija su estado, haga clic en el botón <b>ir</b> para completar los tres pasos y estará en su camino a la comodidad de los pagos directos.",
  "Home.Page.P3": "Para cambiar su información bancaria por favor envíe una nueva suscripción. Espere 4 a 6 semanas.",
  "Home.Page.P4": "<b>Para cancelar el servicio</b> usted debe comunicarse directamente con el Facturador. Llame al número de teléfono de servicio al cliente en su factura.",
  "Home.Page.P5": "Nota: Si prefiere enviar su inscripción por correo, visite la página del <a href=\"printableform\">Formulario Imprimible</a> para descargar un formulario de inscripción que se puede enviar por correo.",
  "Home.Page.P6": "Empresas: Envíe información en nuestro <a href=\"request-info\">Formulario de información de la empresa</a>, para obtener más información sobre cómo ofrecer este servicio a sus clientes.",
  "Home.Page.People.Quote1": "\"Una forma confiable y segura de pagar mis cuentas\".",
  "Home.Page.People.Quote2": "\"Guarda mi tiempo y el costo de envío\".",
  "Home.Page.People.Quote3": "\"Podemos viajar y nuestras facturas se pagan automáticamente\".",
  "Home.Page.People.Quote4": "\"Esto es genial! No más cheques para escribir.\"",
  "Home.Page.People.Quote5": "\"No más preocupaciones acerca de retrasos en los pagos\".",
  "MainForm.Button.Print": "Impresión",
  "MainForm.Errors.AcctNumberMissing": "Número de cuenta Falta",
  "MainForm.Errors.AddressMissing": "Calle de la falta de dirección",
  "MainForm.Errors.AuthorizationLettersNumbers": "Código de autorización sólo puede contener letras y números. Por favor corregir el código de autorización y haga clic de nuevo en el botón 'Autorizar servicio'.",
  "MainForm.Errors.AuthorizationNot4Chars": "Código de autorización debe ser exactamente 4 caracteres de longitud. Por favor corregir el código de autorización y haga clic de nuevo en el botón 'Autorizar servicio'.",
  "MainForm.Errors.CityMissing": "Falta la ciudad",
  "MainForm.Errors.EmailMissing": "Correo electrónico no es necesario",
  "MainForm.Errors.FICityMissing": "Falta la ciudad de la institución financiera",
  "MainForm.Errors.FINameMissing": "Falta el nombre de la institución financiera",
  "MainForm.Errors.NameMissing": "Falta el nombre",
  "MainForm.Errors.PhoneMissing": "Número de teléfono no es necesario",
  "MainForm.Errors.PINMissing": "Número de PIN",
  "MainForm.Errors.RoutingNumberMissing": "Falta número de enrutamiento",
  "MainForm.Errors.StateMissing": "Falta el estado",
  "MainForm.Errors.ZipMissing": "Falta Código Postal",
  "MainForm.Page.Authorize.Description": "Para completar su registro, debe utilizar un código de autorización.  El código sirve como su firma autorizando su inscripción de pago directo de Internet.",
  "MainForm.Page.Authorize.Instructions1": "Por favor componen e introduzca un código de 4 caracteres (alfanumérico).",
  "MainForm.Page.Authorize.Instructions2": "Vuelva a introducir el código para confirmar.",
  "MainForm.Page.Authorize.Label.NextSteps": "Una vez haya introducido su código de autorización, haga clic en el botón <b>Siguiente paso</b> para introducir la información de la empresa.",
  "MainForm.Page.Authorize2.Button.Edit": "Editar",
  "MainForm.Page.Authorize2.Error.BillerAcctNumMissing": "Falta de número de cuenta",
  "MainForm.Page.Authorize2.Error.BillerInfoMissing": "¡Información de facturación faltante!",
  "MainForm.Page.Authorize2.Label.AcctNumber": "Número de cuenta",
  "MainForm.Page.Authorize2.Label.Address": "Dirección",
  "MainForm.Page.Authorize2.Label.Biller": "Facturador",
  "MainForm.Page.Authorize2.Label.Email": "Dirección de correo electrónico",
  "MainForm.Page.Authorize2.Label.FICity": "Ciudad de institución financiera",
  "MainForm.Page.Authorize2.Label.FIName": "Nombre de la institución financiera",
  "MainForm.Page.Authorize2.Label.Name": "Nombre",
  "MainForm.Page.Authorize2.Label.Phone": "Teléfono",
  "MainForm.Page.Authorize2.Label.RoutingNumber": "Número de identificación",
  "MainForm.Page.Authorize2.Trailer.P1": "La información introducida en este sitio Web se proporcionará a su empresa (s) con el único propósito de suscripción de pago directo. La entrega de esta información está protegida por un sitio de Internet seguro.",
  "MainForm.Page.Authorize2.Trailer.P2": "Autorizo a la compañía (s) indicada para descontar mis pagos de cuenta corriente o cuenta de ahorros enumerados.  Entiendo que puedo controlar mis pagos, y si en cualquier momento decido suspender este servicio de pago, notificaré a cada empresa.",
  "MainForm.Page.Authorize2.Trailer.P3": "Para completar su registro, debe proporcionar un <b>Código de autorización</b>. Un <b>Código de autorización</b> es <u>cualquier</u> secuencia de 4 dígitos alfanuméricos. El código sirve como su firma autorizando su Inscripción de pago directo por Internet.",
  "MainForm.Page.Authorize2.Trailer.P4": "Usted recibirá una confirmación por correo electrónico (si se proporciona la electrónica).  Imprima esta pantalla para sus archivos. Permita 4 a 6 semanas para su procesamiento.",
  "MainForm.Page.Authorize2.Trailer.P5": "Marque esta casilla si no puede imprimir la pantalla y desea una copia impresa por correo.",
  "MainForm.Page.BankingInfo.Description.P1": "Se proporcionará la información bancaria para su compañía (s) con el único propósito de suscripción de pago directo. La entrega de esta información está protegida por un sitio seguro en Internet.",
  "MainForm.Page.BankingInfo.Description.P2": "Ingrese a continuación la información que aparece en la parte inferior de su cheque (introducir caracteres especiales no sólo números).",
  "MainForm.Page.BankingInfo.Label.AccountNumber": "Número de cuenta",
  "MainForm.Page.BankingInfo.Label.AccountType": "Tipo de cuenta",
  "MainForm.Page.BankingInfo.Label.Checking": "Corriente",
  "MainForm.Page.BankingInfo.Label.CheckingOrShareDraft": "Cheques o compartir borrador",
  "MainForm.Page.BankingInfo.Label.FICityState": "Ciudad de la institución financiera, estado",
  "MainForm.Page.BankingInfo.Label.FIName": "Nombre de la institución financiera",
  "MainForm.Page.BankingInfo.Label.HowToGetRouting": "Obtenga el <b>Número de ruta bancaria</b> de su banco y el <b>Número de cuenta</b> de su estado de cuenta de ahorros. Usted puede incurrir de servicios bancarios para los retiros automatizados excesivos.",
  "MainForm.Page.BankingInfo.Label.NextSteps": "Haga clic en el botón <b>Siguiente paso</b> para revisar y enviar su registro.",
  "MainForm.Page.BankingInfo.Label.RoutingNumber": "Número de identificación bancaria",
  "MainForm.Page.BankingInfo.Label.Savings": "Ahorro",
  "MainForm.Page.BankingInfo.Label.UseCheck": "Utilizar un cheque no un comprobante de depósito para obtener esta información",
  "MainForm.Page.BillerInfo.Description": "Introduzca su <b>Información de cuenta de facturación</b> a continuación.  Seleccione todas sus empresas de los menús desplegables e introduzca su número de cuenta correspondiente.",
  "MainForm.Page.BillerInfo.Error.AccountNumberMissing": "Debe proporcionarse un Facturador <b>Número de cuenta</b> para cada empresa seleccionada a continuación.  Aparece en la factura de su compañía",
  "MainForm.Page.BillerInfo.Label.AccountNumber": "Número de cuenta",
  "MainForm.Page.BillerInfo.Label.Company": "Empresa",
  "MainForm.Page.BillerInfo.Label.NextSteps": "Una vez introducidos todas sus empresas y la información de la cuenta, haga clic en el botón <b>Siguiente paso</b> para ingresar información personal.",
  "MainForm.Page.BillerInfo.Label.SelectCompany": "Seleccione una compañía",
  "MainForm.Page.Billers.Description": "<b>Los pagos directos</b> son ofrecidos por muchas compañías para que los clientes pueden\n¿han pagado sus facturas recurrentes automáticamente.  La siguiente oferta de empresas\nel servicio en el estado que ha elegido.  Para inscribirse, presione el botón de abajo\ny completar los cuatro sencillos pasos y estás en su camino a la conveniencia de\npagos directos.",
  "MainForm.Page.Billers.Label.NextSteps": "Haga clic en el botón <b>Siguiente paso</b> para comenzar el proceso de inscripción.",
  "MainForm.Page.Cookies.Description": "Cookies del navegador deben estar activadas para que este sitio trabajar",
  "MainForm.Page.Cookies.HowTo": "Cómo habilitar las cookies del navegador",
  "MainForm.Page.Cookies.Enable1": "<b><nobr>Internet Explorer 5.5</nobr> o más</b><td valign=\"top\">Ir a la pestaña de <b>seguridad</b> en el cuadro de diálogo <b>Opciones de herramientas: Internet</b> y haga clic en el botón <b>Nivel personalizado...</b> .</td> Habilite la opción de <b>Permitir cookies de cada sesión (no almacenadas)</b> .",
  "MainForm.Page.Cookies.Enable2": "<b><nobr>Internet Explorer 6.0</nobr> o posterior</b><td valign=\"top\">Vaya a la ficha de <b>Privacidad</b> en el cuadro de diálogo <b>Opciones de herramientas: Internet</b> y ajuste el deslizador a medio.</td>",
  "MainForm.Page.Footer.Button.AuthorizeStep": "Autorizar servicio",
  "MainForm.Page.Footer.Button.BankingChange": "Cambiar información bancaria",
  "MainForm.Page.Footer.Button.ChooseState": "«Volver a elegir estado",
  "MainForm.Page.Footer.Button.CloseWindow": "Cerrar ventana",
  "MainForm.Page.Footer.Button.NextStep": "Paso siguiente»",
  "MainForm.Page.Footer.Button.PersonalChange": "Cambio de información Personal",
  "MainForm.Page.Footer.Button.PrevStep": "«Paso anterior",
  "MainForm.Page.Footer.Button.ReturnToHome": "Volver al inicio",
  "MainForm.Page.Footer.Button.SignupsChange": "Información de la compañía de cambio",
  "MainForm.Page.Footer.Button.SummaryStep": "Volver a autorizar el servicio",
  "MainForm.Page.PersonalInfo.Description": "Por favor ingrese su <b>Información Personal</b> a continuación.",
  "MainForm.Page.PersonalInfo.Label.Address": "Dirección (de facturación o de servicio)",
  "MainForm.Page.PersonalInfo.Label.City": "Ciudad",
  "MainForm.Page.PersonalInfo.Label.Email": "Dirección de correo electrónico",
  "MainForm.Page.PersonalInfo.Label.EmailConfirmOnly": "La dirección de correo electrónico siempre se utilizará para la confirmación, siempre.",
  "MainForm.Page.PersonalInfo.Label.FirstName": "Nombre",
  "MainForm.Page.PersonalInfo.Label.LastName": "Apellido",
  "MainForm.Page.PersonalInfo.Label.NextSteps": "Haga clic en el botón <b>Siguiente paso</b> para ingresar su información bancaria.",
  "MainForm.Page.PersonalInfo.Label.Optional": "opcional",
  "MainForm.Page.PersonalInfo.Label.Phone": "Número de teléfono",
  "MainForm.Page.PersonalInfo.Label.State": "Estado",
  "MainForm.Page.PersonalInfo.Label.Zip": "Código Postal",
  "MainForm.Page.Title.Authorization": "Autorización",
  "MainForm.Page.Title.AuthorizeService": "Autorizar servicio - para enviar, haga clic en autorizar servicio",
  "MainForm.Page.Title.BankingInformation": "Información bancaria",
  "MainForm.Page.Title.Companies": "Empresas participantes",
  "MainForm.Page.Title.CompanyInfo": "Información de la empresa",
  "MainForm.Page.Title.CookiesRequired": "Cookies requeridas",
  "MainForm.Page.Title.PersonalInfo": "Información personal",
  "MainForm.Page.Title.PrintInfo": "Imprimir confirmación",
  "MainForm.Page.Title.ThankYou": "Gracias por utilizar DirectPaymentPlan.com",
  "MainForm.Page.WrapUp.Label.CheckBill": "Revise su cuenta de teléfono",
  "MainForm.Page.WrapUp.P1": "Este servicio comenzará en 4 a 6 semanas.  <b>Por favor continúe pagando sus facturas hasta que cada empresa le notifique en su declaración, que se han activado los pagos directos.</b>",
  "MainForm.Page.WrapUp.P2": "Si cambia la información bancaria permita 4 a 6 semanas para que se den efecto los cambios.  <b>Su pago se deducirá de su cuenta anterior hasta que la nueva información de la cuenta pueda utilizarse.</b>",
  "MainForm.Page.WrapUp.P3": "Para cancelar su servicio de pago directo, póngase en contacto con el Departamento de servicio de empresa (s) utilizando el número de teléfono en su factura.",
  "MainForm.Page.WrapUp.P4": "Para obtener información adicional acerca de su compañía (s) dude en llamar al número de teléfono a continuación o visite su sitio de Internet.",
  "MainForm.Page.WrapUp.ThankYou": "Gracias por utilizar DirectPaymentPlan.com",
  "MainForm.Strings.CheckingAccount": "Cheques o compartir borrador",
  "MainForm.Strings.SavingsAccount": "Cuenta de ahorros",
  "Privacy.Page.Description": "Bienvenidos a la web de <I>DirectPaymentPlan.com</I> .  A continuación encontrará nuestra política de privacidad con respecto a cualquier información personal que pueda ser suministrada a través esta página web.  Nuestro objetivo es proteger su información en el proceso de inscripción le en al servicio de pago directo.",
  "Privacy.Page.Header": "Política de privacidad",
  "Privacy.Page.Section1.Header": "Información requerida para inscribirse en los pagos directos",
  "Privacy.Page.Section1.P1": "Este sitio web es operado con el fin de inscribir a los clientes (particulares y empresas) para servicios de pago directo.  Los pagos directos (DP) es un servicio proporcionado por empresas (utilidad y utilidad como empresas) a realizar una cuenta de institución financiera para pagar una factura recurrente.   Inscribirse en DP requiere que los clientes proporcionen información personal y bancaria a su Facturador para establecer el servicio.  El sitio web de <I>DirectPaymentPlan.com</I> es operado en nombre de las empresas participantes listadas en el sitio que están mencionados en la declaración de privacidad como participantes.",
  "Privacy.Page.Section1.P2": "El proceso de inscripción es para la configuración inicial de su servicio.  Todo contacto futuro sobre el servicio debe hacerse directamente con su Facturador.  Este sitio no proporciona para accesos futuros de su información una vez finalizada la inscripción.  La información se entrega para al Facturador que mantiene el procesamiento de su pago electrónico recurrente adeudado a su cuenta.",
  "Privacy.Page.Section10.Header": "Ingreso al sitio para inscribirse o examinar",
  "Privacy.Page.Section10.P1": "Reunimos y analizamos datos sobre el uso de nuestro sitio web, incluyendo el nombre de dominio, número de visitas, páginas visitadas, duración de la sesión de usuario, etc., para evaluar la utilidad de nuestro sitio web.",
  "Privacy.Page.Section11.Header": "Contar con nuestro compromiso con su privacidad",
  "Privacy.Page.Section11.P1": "Usted puede contar con nosotros para mantenerle informado sobre cómo protegemos su privacidad y limitar el intercambio de información que usted nos proporciona.",
  "Privacy.Page.Section2.Header": "Mantener su información segura",
  "Privacy.Page.Section2.P1": "Mantener su información segura es una de nuestras responsabilidades más importantes.  Valoramos su confianza y aseguramos manejar la información con cuidado.   En el proceso de hacer negocios recopilamos y procesamos su información para su entrega a las empresas elegidas para configurar su servicio de pago directo (DP).  Protegemos la información de acuerdo con los procedimientos y normas de seguridad establecidas.  Educamos a nuestros empleados sobre la importancia de la confidencialidad y privacidad de los clientes.",
  "Privacy.Page.Section3.Header": "Mantener la información exacta",
  "Privacy.Page.Section3.P1": "Es muy importante mantener su información de cuenta precisa.  Si encuentra que la información de su cuenta es incorrecta o no actual póngase en contacto con sus empresas directamente al número telefónico que aparece en su estado de cuenta.",
  "Privacy.Page.Section4.Header": "Cómo se utiliza su información:",
  "Privacy.Page.Section4.SubSection1.Header": "Nombre, dirección y número de teléfono",
  "Privacy.Page.Section4.SubSection1.P1": "Sus empresas requieren su nombre, dirección y número de teléfono para identificarlo a usted en el proceso de configuración del servicio de pago directo (DP).  Facturadores también pueden utilizar este en contacto con usted para obtener la información que falta para completar la inscripción.",
  "Privacy.Page.Section4.SubSection2.Header": "Información bancaria",
  "Privacy.Page.Section4.SubSection2.P1": "Su información bancaria que incluye su institución financiera número de ruta y tránsito, número de cuenta y tipo de cuenta se utiliza para configurar la información de pago directo en el servicio de facturación de Facturador o a través de su compañía de servicio de terceros.  La información se utiliza en cada período de facturación para originar débitos electrónicos a su cuenta para recoger su pago.  Esta información se mantiene en sus registros de facturación de las empresas que ha seleccionado para los servicios de DP.  Su Facturador puede ser necesario para compartir esta información en relación con la investigación de fraude, adquisición o venta de su cuenta de Facturador a otro proveedor de servicios.",
  "Privacy.Page.Section4.SubSection3.Header": "Código de autorización",
  "Privacy.Page.Section4.SubSection3.P1": "Cuando comience el proceso de inscripción se le pedirá que introduzca un código de autorización.  El código se mantendrá con su información de inscripción y no será compartido con ningún tercero.",
  "Privacy.Page.Section4.SubSection4.Header": "Dirección de correo electrónico de inscripción",
  "Privacy.Page.Section4.SubSection4.P1": "Se utilizará su dirección de correo electrónico para confirmar su inscripción durante la inscripción. El correo electrónico regular no se cifra de esa manera la información devuelta en su confirmación no contendrá la información real de Facturador y cuenta bancaria. Su Facturador puede retener esta información para notificaciones con usted y para otros fines.",
  "Privacy.Page.Section4.SubSection5.Header": "Solicitudes por correo electrónico",
  "Privacy.Page.Section4.SubSection5.P1": "Correo electrónico recibido a este sitio sirve para proporcionar como medio para que usted pueda hacer preguntas o para solicitar los servicios de empresas que no sean participantes en este programa.  Su contenido de correo electrónico y dirección con preguntas se utilizará para el seguimiento y para ofrecer una respuesta.  Su correo electrónico solicitando acción se reenviará al facturador apropiado.",
  "Privacy.Page.Section4.SubSection5.P2": "Nota:  Toda la información anterior se proporciona para su Facturador (excepto el código de autorización) para la inscripción de los pagos directos.  Póngase en contacto con su Facturador utilizando el número de teléfono en su estado de cuenta para preguntas acerca de su política de privacidad y el uso y la seguridad de su información.",
  "Privacy.Page.Section5.Header": "Enlace a DirectPaymentPlan.com",
  "Privacy.Page.Section5.P1": "Si su método de entrar en la <I>DirectPaymentPlan.com</I> es como resultado de un enlace proporcionado por otro sitio Web este sitio no puede proporcionar la privacidad de la información que se utilizó para llegar a este sitio.  Una vez se encuentre en este sitio los comentarios de privacidad y seguridad declarados aplicarán.",
  "Privacy.Page.Section6.Header": "Desde directpaymentplan.com",
  "Privacy.Page.Section6.P1": "Este sitio contiene enlaces a los sitios Web de empresas participantes.  Nuestra política de privacidad no es aplicable a otros sitios que usted se enlace a esos sitios web.",
  "Privacy.Page.Section7.Header": "Compartir su información",
  "Privacy.Page.Section7.P1": "Con excepción de lo descrito en este sitio no se compartirá esta información con cualquier otra parte.",
  "Privacy.Page.Section8.Header": "Acerca de las cookies",
  "Privacy.Page.Section8.P1": "Las cookies es la capacidad para colocar archivos en su unidad de disco para interactuar con los usuarios de internet y sólo puede ser leído por el servidor que se le dio a usted.  Este sitio utiliza cookies para completar su inscripción.",
  "Privacy.Page.Section9.Header": "Proteger a nuestros niños",
  "Privacy.Page.Section9.P1": "A sabiendas no solicitamos datos de los niños y no comercializamos deliberadamente a los niños.  Reconocemos que la protección de identidades y la privacidad en línea de los niños son importantes en que la responsabilidad recae con ambos la industria en línea y con los padres.",
  "MainForm.Errors.AuthorizationCodeDoesNotMatch": "Códigos de autorización no coinciden. Vuelva a introducir.",
  "MainForm.Errors.AuthorizationCodeExactly4Chars": "Código de autorización debe ser exactamente 4 caracteres de longitud.",
  "MainForm.Errors.MissingInformationWarning": "No existe información válida es necesaria para completar el formulario. Los elementos que faltan se muestran en \"Rojo\". Por favor corrija estos artículos antes de autorizar la inscripción.",
  "MainForm.Label.AuthorizationCode": "Código de autorización:",
  "MainForm.Label.AuthorizationCode2": "Código de autorización",
  "MainForm.Label.AuthorizationDate": "Fecha:",
  "MainForm.Label.AuthorizationRecordNumber": "Número de registro:",
  "MainForm.Page.Authorize2.Label.BankingInfo": "Información bancaria",
  "MainForm.Page.Authorize2.Label.CompanyInfo": "Información de la empresa",
  "MainForm.Page.Authorize2.Label.PersonalInfo": "Información personal",
  "Header.Tagline": "Pago directo de uso para pagar sus facturas automáticamente",
  "Contact.CheckBill": "Revise su estado de cuenta para información telefónica",
  "MultiStepProgress.Step1": "Empresa",
  "MultiStepProgress.Step2": "Dirección",
  "MultiStepProgress.Step3": "Bancaria",
  "MultiStepProgress.Step4": "Autorizar",
  "PrintableForm.Header": "Formulario Imprimible",
  "PrintableForm.P1": "Haga clic en su estado a continuación para descargar un formulario de inscripción imprimible. Siga las instrucciones del formulario para inscribirse en pagos directos.",
}

export default DATA
