import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectLanguage, getLocaleString } from '../store/languageSlice';
import {
	setField,
	setBillerInfo,
	selectField,
	selectBillerInfo,
	selectSignupState,
	selectAllBillerInfo,
	SIGNUPFIELDS,
	BILLERINFOFIELDS
} from '../store/signupSlice';
import PageNavigation from '../page/PageNavigation';
import AlertNoState from '../page/AlertNoState';

import { SectionBillerInfo, SectionPersonalInfo, SectionBankingInfo } from './Authorize';
import { getBillerDetails, undefinedIfEmpty, onlyLastFour } from '../utils/signupUtils';

import { Form, Row, Col, Card, Button } from 'react-bootstrap'

import SiteData from '../static/siteData'

function PrintSignup({ match, location }) 
{
	let items = [];

	let langId = useSelector(selectLanguage);  

	let stateId = useSelector(selectSignupState);

	let stateBillerInfo = SiteData.states[stateId].billerInfo;

	let signupBillerInfos = useSelector(selectAllBillerInfo);

	let goodBillerAccts = 0;
	signupBillerInfos.map(function(signupBillerInfo) {
		if (signupBillerInfo[BILLERINFOFIELDS.ACCOUNTNUMBER] !== undefined)
			goodBillerAccts++;
	})
	
	let personalInfo = {};
	
	personalInfo.firstName = undefinedIfEmpty(useSelector(state => selectField(state, SIGNUPFIELDS.FIRSTNAME)));
	personalInfo.lastName = undefinedIfEmpty(useSelector(state => selectField(state, SIGNUPFIELDS.LASTNAME)));
	personalInfo.address = undefinedIfEmpty(useSelector(state => selectField(state, SIGNUPFIELDS.ADDRESS)));
	personalInfo.city = undefinedIfEmpty(useSelector(state => selectField(state, SIGNUPFIELDS.CITY)));
	personalInfo.state = undefinedIfEmpty(useSelector(state => selectField(state, SIGNUPFIELDS.STATE)));
	personalInfo.zip = undefinedIfEmpty(useSelector(state => selectField(state, SIGNUPFIELDS.ZIP)));
	personalInfo.phoneNumber = undefinedIfEmpty(useSelector(state => selectField(state, SIGNUPFIELDS.PHONENUMBER)));
	personalInfo.emailAddress = undefinedIfEmpty(useSelector(state => selectField(state, SIGNUPFIELDS.EMAILADDRESS)));
	personalInfo.name = personalInfo.firstName?(personalInfo.lastName?personalInfo.firstName+" "+personalInfo.lastName:personalInfo.firstName):undefined;

	let bankingInfo = {};

	bankingInfo.fiName = undefinedIfEmpty(useSelector(state => selectField(state, SIGNUPFIELDS.FINAME)));
	bankingInfo.fiCity = undefinedIfEmpty(useSelector(state => selectField(state, SIGNUPFIELDS.FICITY)));
	bankingInfo.fiRoutingNumber = onlyLastFour(undefinedIfEmpty(useSelector(state => selectField(state, SIGNUPFIELDS.FIROUTINGNUMBER))));
	bankingInfo.fiAccountNumber = onlyLastFour(undefinedIfEmpty(useSelector(state => selectField(state, SIGNUPFIELDS.FIACCOUNTNUMBER))));
	bankingInfo.fiAccontTypeChecking = useSelector(state => selectField(state, SIGNUPFIELDS.FIACCONTTYPECHECKING));
	
	if (bankingInfo.fiAccountNumber)
	{
		bankingInfo.fiAccountNumber = bankingInfo.fiAccountNumber + " (" + (bankingInfo.fiAccontTypeChecking?getLocaleString(langId, "MainForm.Strings.CheckingAccount"):getLocaleString(langId, "MainForm.Strings.SavingsAccount")) + ")";
	}

	let authorizationCode = useSelector(state => selectField(state, SIGNUPFIELDS.AUTHORIZATIONCODE));
	let recordNumber = useSelector(state => selectField(state, SIGNUPFIELDS.FORMSEQNUMBER));
	let customerDate = useSelector(state => selectField(state, SIGNUPFIELDS.CUSTOMERDATE));

	const labelWidth = 5;

	return <div className="page-content">
		{
			stateId === 'XX' ?
			<AlertNoState />
			:
			<div>
				<div>
					<div className="authorize-spacer"></div>
					<h3 className="form-title">{ getLocaleString(langId, "MainForm.Page.Title.PrintInfo") }</h3>
					<div className="print-spacer-header"></div>
					<Button variant="secondary" onClick={ () => { window.print(); } }>{ getLocaleString(langId, "MainForm.Button.Print") }</Button>
					<div className="print-spacer-header"></div>
					<div><span className="print-info-label">{ getLocaleString(langId, "MainForm.Label.AuthorizationRecordNumber") }</span><span  className="print-info-value">{ recordNumber }</span></div>
					<div><span className="print-info-label">{ getLocaleString(langId, "MainForm.Label.AuthorizationCode") }</span><span  className="print-info-value">{ authorizationCode }</span></div>
					<div><span className="print-info-label">{ getLocaleString(langId, "MainForm.Label.AuthorizationDate") }</span><span  className="print-info-value">{ customerDate }</span></div>
					<div className="print-spacer-header"></div>
					<Card body className="authorize-card text-center">
						<Card.Title>{ getLocaleString(langId, "MainForm.Page.Title.CompanyInfo") }</Card.Title>
						<SectionBillerInfo signupBillerInfos={ signupBillerInfos } stateBillerInfo={ stateBillerInfo } labelWidth={ labelWidth } />
					</Card>
					<div className="authorize-card-spacer"></div>
					<Card body className="authorize-card text-center">
						<Card.Title>{ getLocaleString(langId, "MainForm.Page.Title.PersonalInfo") }</Card.Title>
						<SectionPersonalInfo personalInfo={ personalInfo } labelWidth={ labelWidth }/>
					</Card>
					<div className="authorize-card-spacer"></div>
					<Card body className="authorize-card text-center">
						<Card.Title>{ getLocaleString(langId, "MainForm.Page.Title.BankingInformation") }</Card.Title>
						<SectionBankingInfo bankingInfo={ bankingInfo } labelWidth={ labelWidth }/>
					</Card>
					<div className="authorize-spacer"></div>
					<p dangerouslySetInnerHTML={{__html: getLocaleString(langId, "MainForm.Page.Authorize2.Trailer.P1")}}></p>
					<p dangerouslySetInnerHTML={{__html: getLocaleString(langId, "MainForm.Page.Authorize2.Trailer.P4")}}></p>
				</div>
				<PageNavigation match={ match } location={ location } next="/signup/thanks" />
			</div>
		}
	</div>
}

export default PrintSignup;