import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	useParams,
  } from "react-router-dom";
  import { Carousel } from 'react-bootstrap'
  
  import {
	toggleLanguage,
	setLanguage,
	LANGUAGES,
	selectLanguage,
	getLocaleString
} from '../store/languageSlice';

import SiteData from '../static/siteData'

function HomePageImages(props) {  

    let langId = useSelector(selectLanguage);
    
	return <Carousel>
    <Carousel.Item>
        <img
            className="d-block w-100"
            src={process.env.PUBLIC_URL + '/img/image1.jpg'}
            alt={ getLocaleString(langId, "Home.Page.People.Quote1")}
            />
        <Carousel.Caption>
            <h3>{ getLocaleString(langId, "Home.Page.People.Quote1")}</h3>
        </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
        <img
            className="d-block w-100"
            src={process.env.PUBLIC_URL + '/img/image2.jpg'}
            alt={ getLocaleString(langId, "Home.Page.People.Quote2")}
            />
        <Carousel.Caption>
            <h3>{ getLocaleString(langId, "Home.Page.People.Quote2")}</h3>
        </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
        <img
            className="d-block w-100"
            src={process.env.PUBLIC_URL + '/img/image3.jpg'}
            alt={ getLocaleString(langId, "Home.Page.People.Quote3")}
            />
        <Carousel.Caption>
            <h3>{ getLocaleString(langId, "Home.Page.People.Quote3")}</h3>
        </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
        <img
            className="d-block w-100"
            src={process.env.PUBLIC_URL + '/img/image4.jpg'}
            alt={ getLocaleString(langId, "Home.Page.People.Quote4")}
            />
        <Carousel.Caption>
            <h3>{ getLocaleString(langId, "Home.Page.People.Quote4")}</h3>
        </Carousel.Caption>
    </Carousel.Item>
</Carousel>
}

export default HomePageImages;