import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-steps/dist/bootstrap-steps.min.css';

import React, { useEffect } from 'react';
import './App.css';

import Header from './page/Header';
import Footer from './page/Footer';

import PersonalInfo from './views/PersonalInfo';
import BillerInfo from './views/BillerInfo';
import HomePage from './views/HomePage';
import BankingInfo from './views/BankingInfo';
import Authorize from './views/Authorize';
import MoreInfo from './views/MoreInfo';
import Thankyou from './views/Thankyou';
import RequestInfo from './views/RequestInfo';
import PrintSignup from './views/PrintSignup';
import PrivacyPolicy from './views/PrivacyPolicy';
import FeedbackForm from './views/FeedbackForm';
import BillerContactList from './views/BillerContactList';
import PrintableForm from './views/PrintableForm';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useLocation,
} from "react-router-dom";

function App() {

	return (
		<div className="container-fluid">
			<Header />
				<div>
					<Switch>
						<Route exact path="/" component={ HomePage } />
						<Route exact path="/index.html" component={ HomePage } />
						<Route exact path="/signup/company" component={ BillerInfo } />
						<Route exact path="/signup/address" component={ PersonalInfo } />
						<Route exact path="/signup/bank" component={ BankingInfo } />
						<Route exact path="/signup/authorize" component={ Authorize } />
						<Route exact path="/signup/print" component={ PrintSignup } />
						<Route exact path="/signup/thanks" component={ Thankyou } />
						<Route exact path="/more-info" component={ MoreInfo } />
						<Route exact path="/privacy-policy" component={ PrivacyPolicy } />
						<Route exact path="/request-info" component={ RequestInfo } />
						<Route exact path="/feedback-form" component={ FeedbackForm } />
						<Route exact path="/biller-contact-list" component={ BillerContactList } />
						<Route exact path="/printableform" component={ PrintableForm } />
					</Switch>
				</div>
			<Footer />
		</div>  
	);
}

export default App;
