import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectLanguage, getLocaleString } from '../store/languageSlice';
import {
	setField,
	setBillerInfo,
	selectField,
	selectBillerInfo,
	SIGNUPFIELDS,
	BILLERINFOFIELDS
} from '../store/signupSlice';
import PageNavigation from '../page/PageNavigation';

import { Form, Row, Col, Card } from 'react-bootstrap'

function MoreInfo({ match, location }) 
{
	let langId = useSelector(selectLanguage);  

	let faq = [];

	for(let i=1;i<=10;i++) {

		let q = "FAQ.List.Item"+i+".Q";
		let a = "FAQ.List.Item"+i+".A";

		faq.push(<div>
			<Row className="faq-question-row">
				<Col className="faq-question-col-head" xs="auto"><p>Q.</p></Col>
				<Col className="faq-question-col-text"><p dangerouslySetInnerHTML={{__html: getLocaleString(langId, q)}}></p></Col>
			</Row>
			<Row className="faq-answer-row">
				<Col className="faq-question-col-head" xs="auto"><p>A.</p></Col>
				<Col className="faq-question-col-text"><p dangerouslySetInnerHTML={{__html: getLocaleString(langId, a)}}></p></Col>
			</Row>
		</div>);
	}

	return <div className="page-content">
		<div className="page-content-simple-spacer"></div>
		<h3>{ getLocaleString(langId, "FAQ.Page.Header") }</h3>
		<p dangerouslySetInnerHTML={{__html: getLocaleString(langId, "FAQ.Page.Description")}}></p>

		{ faq }

		<p dangerouslySetInnerHTML={{__html: getLocaleString(langId, "FAQ.Page.Trailer1")}}></p>
		<p dangerouslySetInnerHTML={{__html: getLocaleString(langId, "FAQ.Page.Trailer2")}}></p>
		
	</div>
}

export default MoreInfo;