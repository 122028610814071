import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectLanguage, getLocaleString } from '../store/languageSlice';
import {
	setField,
	setBillerInfo,
	selectField,
	selectBillerInfo,
	selectSignupState,
	SIGNUPFIELDS,
	BILLERINFOFIELDS
} from '../store/signupSlice';
import PageNavigation from '../page/PageNavigation';
import AlertNoState from '../page/AlertNoState';

import { Form, Row, Col, Card } from 'react-bootstrap'

function Thankyou({ match, location }) 
{
	let items = [];

	let langId = useSelector(selectLanguage);  
	let stateId = useSelector(selectSignupState);

	return <div className="page-content">
		{
			stateId === 'XX' ?
			<AlertNoState />
			:
			<div>
				<div>
					<div className="authorize-spacer"></div>
					<h3 className="form-title">{ getLocaleString(langId, "MainForm.Page.WrapUp.ThankYou") }</h3>
					<div className="authorize-spacer"></div>
					<p dangerouslySetInnerHTML={{__html: getLocaleString(langId, "MainForm.Page.WrapUp.P1")}}></p>
					<p dangerouslySetInnerHTML={{__html: getLocaleString(langId, "MainForm.Page.WrapUp.P2")}}></p>
					<p dangerouslySetInnerHTML={{__html: getLocaleString(langId, "MainForm.Page.WrapUp.P3")}}></p>
				</div>
				<PageNavigation match={ match } location={ location } next="/" nextLabel="MainForm.Page.Footer.Button.ReturnToHome" />
			</div>
		}
	</div>
}

export default Thankyou;