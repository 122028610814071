import { configureStore } from '@reduxjs/toolkit';
import signupReducer from '../store/signupSlice';
import languageReducer from '../store/languageSlice';

export default configureStore({
	reducer: {
		signup: signupReducer,
		language: languageReducer,
	},
});
